import React from "react";
import { Card, Button, Form, Row, Col } from "reactstrap";

import { useTranslation } from "react-i18next";
import PageTitle from "../Common/PageTitle";

function FailureForm(props) {
    const { t, i18n } = useTranslation();
    let mail_to = "";
    let first_name = "";
    let brand_name = "";
    let user_cell = "";
    let user_email = "";
    let source = "";
    let { onboarding = {} } = props;
    const { closer = {} } = onboarding;
    const { phone = "" } = closer;
    let signup_form = onboarding ? onboarding.signup_form ?? null : null;
    if (signup_form) {
        brand_name = signup_form.brand_name ?? "";
        if (signup_form.crm_representative) {
            first_name = signup_form.crm_representative.first_name ?? "";
            user_cell = signup_form.crm_representative.phone ?? "";
            user_email = signup_form.crm_representative.email ?? "";
            source = signup_form.source ?? "";
        }
    }

    mail_to = `mailto:support@rushable.io?subject=Need help verifying my business&body=Hi, this is ${first_name} from ${brand_name}, I’m having issue verifying my business for using Rushable.<br><br>My cellphone is ${user_cell}<br>My account email is ${user_email}<br><br>Please assist.`;

    return (
        <Card className="form-card">
            <Form onSubmit={(e) => props.goToStep(3)}>
                {" "}
                <PageTitle>{t("failure.title")}</PageTitle>
                <Row>
                    {source === "affiliate" ? null : (
                        <Col>
                            {" "}
                            <a href={`tel:${phone}`}>
                                <Button
                                    block
                                    className="m-0 w-100 p-0 mw-100"
                                    size="lg"
                                    type="button"
                                    disabled={props.confirmPaymentRequest}
                                    // onClick={(e) => window.open(mail_to)}
                                >
                                    {t("failure.contact")}
                                </Button>
                            </a>
                        </Col>
                    )}
                    <Col>
                        <Button
                            color="primary"
                            block
                            className="mt-0"
                            size="lg"
                            onClick={(e) => props.check()}
                        >
                            {t("failure.check")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default FailureForm;
