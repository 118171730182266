import React from "react";
import {
    Form,
    FormGroup,
    Row,
    Col,
    Card,
    Input,
    Label,
    Button,
    CustomInput,
    Alert,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import PageTitle from "../Common/PageTitle";
import { formatPhoneNumber } from "../../utils/digit";

function Login(props) {
    const { t } = useTranslation();

    let { email, first_name, last_name, phone, rep_agrees_to_terms } =
        props.data;
    let { closer } = props.onboarding;
    let closer_first_name = closer.first_name ?? "";
    let closer_last_name = closer.last_name ?? "";

    return (
        <Card className="form-card">
            <PageTitle>
                {t("rep.title_1") +
                    closer_first_name +
                    " " +
                    closer_last_name +
                    t("rep.title_2")}
            </PageTitle>

            <Form onSubmit={props.onSubmit}>
                <Row form>
                    <Col md="6">
                        <FormGroup className="">
                            <Label>{t("rep.first_name")}</Label>
                            <Input
                                type="text"
                                name="first_name"
                                onChange={(e) =>
                                    props.onFormChange(
                                        "first_name",
                                        e.target.value
                                    )
                                }
                                value={first_name ?? ""}
                                required
                            />
                        </FormGroup>
                    </Col>

                    <Col md="6">
                        <FormGroup className="">
                            <Label>{t("rep.last_name")}</Label>
                            <Input
                                type="text"
                                name="last_name"
                                onChange={(e) =>
                                    props.onFormChange(
                                        "last_name",
                                        e.target.value
                                    )
                                }
                                value={last_name ?? ""}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md="6">
                        <FormGroup>
                            <Label>{t("rep.your_email")}</Label>
                            <Input
                                type="email"
                                name="email"
                                onChange={(e) =>
                                    props.onFormChange("email", e.target.value)
                                }
                                value={email ?? ""}
                                required
                            />
                        </FormGroup>
                    </Col>

                    <Col md="6">
                        <FormGroup>
                            <Label>{t("rep.cell_phone")}</Label>
                            <Input
                                type="text"
                                name="phone"
                                disabled={true}
                                // onChange={(e) => props.onFormChange("phone", e.target.value)}
                                value={phone ? formatPhoneNumber(phone) : ""}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md="6">
                        <FormGroup>
                            <Label>{t("rep.password")}</Label>
                            <Input
                                type="password"
                                name="password"
                                onChange={(e) =>
                                    props.onFormChange(
                                        "password",
                                        e.target.value
                                    )
                                }
                                required
                            />
                        </FormGroup>
                    </Col>

                    <Col md="6">
                        <FormGroup>
                            <Label>{t("rep.password_confirmed")}</Label>
                            <Input
                                type="password"
                                name="password_confirmed"
                                onChange={(e) =>
                                    props.onFormChange(
                                        "password_confirmed",
                                        e.target.value
                                    )
                                }
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>{" "}
                <Row form>
                    <Col>
                        <div className="d-flex align-items-center terms-bar">
                            <FormGroup
                                check
                                className="d-flex align-items-center"
                            >
                                <CustomInput
                                    type="checkbox"
                                    id="agreeCheckbox"
                                    name="rep_agrees_to_terms"
                                    required
                                    onChange={(e) =>
                                        props.onFormChange(
                                            "rep_agrees_to_terms",
                                            e.target.checked ? 1 : 0
                                        )
                                    }
                                />
                                <Label for="agreeCheckbox" check>
                                    <div>
                                        {t("rep.accept")}&nbsp;
                                        <a
                                            href="/terms-of-service"
                                            target="_blank"
                                        >
                                            {t("rep.terms")}&nbsp;
                                        </a>
                                        {t("rep.and")}&nbsp;
                                        <a
                                            href="/privacy-policy"
                                            target="_blank"
                                        >
                                            {t("rep.policy")}
                                        </a>
                                        {t("rep.of_rushable")}&nbsp;
                                    </div>
                                </Label>
                            </FormGroup>
                        </div>
                        {props.confirmRepInfoError ? (
                            <Alert color="danger" className="mt-3">
                                {props.confirmRepInfoError}
                            </Alert>
                        ) : null}
                        <Button
                            type="submit"
                            color="primary"
                            block
                            onClick={(e) => props.onSubmit(e)}
                            disabled={
                                props.confirmRepInfoRequest ||
                                rep_agrees_to_terms !== 1
                            }
                        >
                            {props.confirmRepInfoRequest ? (
                                <i className="fad fa-spin fa-spinner mr-2"></i>
                            ) : null}
                            {t("rep.submit")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default Login;
