import React from "react";
import { connect } from "react-redux";
import i18n from "../../i18n";
import { changeStep, changeLanguage } from "../../store/actions/confirm";
import WelcomecallForm from "../../components/Welcomecall/WelcomecallForm";

class WelcomecallIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            language: "english",
        };
        this.changeStep = this.changeStep.bind(this);
    }

    componentDidMount() {
        if (this.props.onboarding) {
            const { signup_form } = this.props.onboarding;
            if (signup_form && signup_form.language) {
                this.setState({
                    language: signup_form.language,
                });
            }
        }
    }

    componentDidUpdate() {}

    changeStep(step) {
        let { src } = this.props.match.params;
        this.props.changeStep({ step: step, src: src });
    }

    languageChange = (type) => {
        if (type !== this.state.language) {
            const { src } = this.props.match.params;
            const params = {
                src,
                language: type,
            };
            this.props.changeLanguage(params);
            i18n.changeLanguage(type);
            this.setState({
                language: type,
            });
        }
    };

    render() {
        if (!this.props.onboarding) return null;
        let { signup_form, welcome_call_links } = this.props.onboarding;
        return (
            <WelcomecallForm
                changeStep={this.changeStep}
                changeStepRequest={this.props.changeStepRequest}
                signup_form={signup_form}
                language={this.state.language}
                languageChange={this.languageChange}
                welcome_call_links={welcome_call_links}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        onboarding: state.auth.onboarding,
        changeStepRequest: state.confirm.changeStepRequest,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeStep: (params) => dispatch(changeStep(params)),
        changeLanguage: (params) => dispatch(changeLanguage(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomecallIndex);
