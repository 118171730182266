import React from "react";
import { connect } from "react-redux";
import BusinessForm from "../../components/Business/BusinessForm";
import { postBusiness } from "../../store/actions/confirm";
import {
    formatDigitOnly,
    onPhoneKeyDown,
    onPhoneKeyUp,
    formatPhoneNumber,
    onEinKeyUp,
    formatEin,
} from "../../utils/digit";

class BusinessIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                business_dba: "",
                business_ein: "",
                business_legal_name: "",
                business_phone: "",
                business_address: "",
                business_address2: "",
                business_city: "",
                business_state: "",
                business_zipcode: "",
            },
        };
        this.renderFormData = this.renderFormData.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onPhoneKeyUp = this.onPhoneKeyUp.bind(this);
        this.onBusinessEinKeyUp = this.onBusinessEinKeyUp.bind(this);
    }

    componentDidMount() {
        this.renderFormData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.onboarding !== this.props.onboarding) {
            this.renderFormData();
        }
    }

    renderFormData() {
        let onboarding = this.props.onboarding;
        if (onboarding) {
            if (onboarding.signup_form) {
                let { business_address } = onboarding.signup_form;
                let address = {};
                if (business_address) {
                    address = {
                        business_address: business_address.line_1,
                        business_address_2: business_address.line_2,
                        business_city: business_address.city,
                        business_state: business_address.state,
                        business_zipcode: business_address.zipcode,
                    };
                }
                let formData = {
                    ...onboarding.signup_form,
                    business_phone: formatPhoneNumber(
                        onboarding.signup_form.business_phone
                    ),
                    business_ein: onboarding.signup_form.business_ein
                        ? formatEin(onboarding.signup_form.business_ein)
                        : null,
                    ...address,
                };
                this.setState({
                    formData,
                });
            }
        }
    }

    onPhoneKeyUp(e) {
        var ph = onPhoneKeyUp(e, e.target.value);
        let formData = {
            ...this.state.formData,
            business_phone: ph,
        };
        this.setState({ formData });
    }

    onFormChange(name, val) {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val,
            },
        });
    }

    onBusinessEinKeyUp(e) {
        var ein = onEinKeyUp(e, e.target.value);
        let formData = {
            ...this.state.formData,
            business_ein: ein,
        };
        this.setState({ formData });
    }

    onSubmit(e) {
        e.preventDefault();
        let { src } = this.props.match.params;
        let data = {
            ...this.state.formData,
            src: src,
            business_phone: formatDigitOnly(this.state.formData.business_phone),
            business_ein: formatDigitOnly(
                this.state.formData.business_ein ?? ""
            ),
        };
        this.props.postBusiness(data);
    }

    goBack() {
        let { src } = this.props.match.params;
        this.props.history.push({
            pathname: `/onboard/${src}/payout`,
        });
    }

    render() {
        return (
            <BusinessForm
                {...this.props}
                data={this.state.formData}
                onFormChange={this.onFormChange}
                onSubmit={this.onSubmit}
                onPrev={this.goBack}
                onPhoneKeyUp={this.onPhoneKeyUp}
                onPhoneKeyDown={onPhoneKeyDown}
                onBusinessEinKeyUp={this.onBusinessEinKeyUp}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        onboarding: state.auth.onboarding,
        confirmBusinessError: state.confirm.confirmBusinessError,
        confirmBusinessRequest: state.confirm.confirmBusinessRequest,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { postBusiness: (params) => dispatch(postBusiness(params)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessIndex);
