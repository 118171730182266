import React, { Component } from "react";
import "./CodeInput.scss";

const CODE_LENGTH = new Array(4).fill(0);
const FIELD_WIDTH = 80;

class CodeInput extends Component {
  input = React.createRef();
  wrap = React.createRef();
  state = {
    value: "",
    focused: false,
    width: 0,
  };

  componentDidMount() {
    this.input.current.focus();
    let width = this.wrap.current.clientWidth;
    this.setState({
      width,
    });
  }

  handleClick = () => {
    this.input.current.focus();
  };
  handleFocus = () => {
    this.setState({ focused: true });
  };
  handleBlur = () => {
    this.setState({
      focused: false,
    });
  };
  handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      let newValue = this.state.value.slice(0, this.state.value.length - 1);
      this.setState({
        value: newValue,
      });
      this.props.onChange(newValue);
    }
  };
  handleChange = (e) => {
    const value = e.target.value;
    let newValue =
      this.state.value.length >= CODE_LENGTH.length
        ? ""
        : (this.state.value + value).slice(0, CODE_LENGTH.length);
    this.setState({
      value: newValue,
    });

    this.props.onChange(newValue);
  };
  render() {
    const { value, focused } = this.state;

    const values = value.split("");

    const selectedIndex =
      values.length < CODE_LENGTH.length
        ? values.length
        : CODE_LENGTH.length - 1;

    const hideInput = !(values.length < CODE_LENGTH.length);

    const gap = (this.state.width - CODE_LENGTH.length * FIELD_WIDTH) / 3;

    return (
      <div
        className="code-input-wrap"
        style={{ justifyContent: "space-between" }}
        onClick={this.handleClick}
        ref={this.wrap}
      >
        <input
          value=""
          type="tel"
          pattern="[0-9]*"
          ref={this.input}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          className="input"
          style={{
            width: FIELD_WIDTH,
            top: "0px",
            bottom: "0px",
            left: `${selectedIndex * (FIELD_WIDTH + gap)}px`,
            opacity: hideInput ? 0 : 1,
            textAlign: "center",
          }}
        />
        {CODE_LENGTH.map((v, index) => {
          const selected = values.length === index;
          const filled =
            values.length === CODE_LENGTH.length &&
            index === CODE_LENGTH.length - 1;
          return (
            <div className="display" key={`code-input-${index}`}>
              {values[index]}
              {(selected || filled) && focused && <div className="shadows" />}
            </div>
          );
        })}
      </div>
    );
  }
}

export default CodeInput;
