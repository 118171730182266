import Cookies from "js-cookie";
import axios from "axios";
import { push } from "connected-react-router";
import { TOKEN_KEY } from "../../utils/constant";

export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";
export const login =
    ({ src, phone, verification_code, from }) =>
    (dispatch) => {
        dispatch({ type: AUTH_LOGIN_REQUEST });
        axios
            .request({
                url: "login",
                data: { src, phone, verification_code },
                method: "post",
            })
            .then(({ data }) => {
                dispatch({
                    type: AUTH_LOGIN_SUCCESS,
                    msg: data.message,
                });
                Cookies.set(TOKEN_KEY, data.data.access_token, {
                    expires: new Date(data.data.expires_at),
                });
                dispatch(landing({ src, refresh: true }));
            })
            .catch((err) => {
                if (err && err.message) {
                    dispatch({
                        type: AUTH_LOGIN_FAIL,
                        msg: err.message,
                        errors: err.errors,
                    });
                } else {
                    dispatch({
                        type: AUTH_LOGIN_FAIL,
                        msg: null,
                        errors: {},
                    });
                }
            });
    };

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const logout =
    (redirect = "/login") =>
    (dispatch) => {
        dispatch({ type: AUTH_LOGOUT });

        let access_token = Cookies.get(TOKEN_KEY);
        if (!access_token) return dispatch(push("/login"));

        Cookies.remove(TOKEN_KEY);
        dispatch(push(redirect));

        axios
            .request({
                url: "logout",
                method: "post",
            })
            .then(({ data }) => {
                if (data.success) {
                    console.log("You have logged out.");
                }
            });
    };

export const AUTH_LANDING_REQUEST = "AUTH_LANDING_REQUEST";
export const AUTH_LANDING_SUCCESS = "AUTH_LANDING_SUCCESS";
export const AUTH_LANDING_FAIL = "AUTH_LANDING_FAIL";
export const landing =
    ({ src, refresh = false, loading = true }) =>
    (dispatch) => {
        dispatch({ type: loading ? AUTH_LANDING_REQUEST : null });

        axios
            .request({
                url: "landing",
                method: "get",
                params: { src: src },
                cache: { ignoreCache: refresh },
            })
            .then(({ data }) => {
                dispatch({
                    type: AUTH_LANDING_SUCCESS,
                    data: data,
                    msg: data.message,
                    errors: data.errors,
                });
            })
            .catch((err) => {
                if (err && err.message) {
                    dispatch({
                        type: AUTH_LANDING_FAIL,
                        msg: err.message,
                        errors: err.errors,
                    });
                }
            });
    };

export const AUTH_SENDCODE_REQUEST = "AUTH_SENDCODE_REQUEST";
export const AUTH_SENDCODE_SUCCESS = "AUTH_SENDCODE_SUCCESS";
export const AUTH_SENDCODE_FAIL = "AUTH_SENDCODE_FAIL";
export const sendcode =
    ({ src, phone, rep_agrees_to_terms, resend = false }) =>
    (dispatch) => {
        dispatch({ type: AUTH_SENDCODE_REQUEST });
        axios
            .request({
                url: "send_code",
                method: "post",
                data: { src, phone, rep_agrees_to_terms },
            })
            .then(({ data }) => {
                dispatch({
                    type: AUTH_SENDCODE_SUCCESS,
                    landing: data.data,
                    msg: data.message,
                    errors: data.errors,
                    success: data.success,
                });
                // ignore if this is resend
                if (!resend) {
                    dispatch(landing({ src, refresh: true }));
                }
            })
            .catch((err) => {
                if (err && err.message) {
                    dispatch({
                        type: AUTH_SENDCODE_FAIL,
                        msg: err.message,
                        errors: err.errors,
                    });
                }
            });
    };
