import React from "react";
import { Input } from "reactstrap";
import { debounce } from "../../utils/tools";
import { connect } from "react-redux";
import { autoFillAddress } from "../../store/actions/confirm";
import classnames from "classnames";
class AddressInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "",
            addressSuggestions: null,
            openConfirmModal: false,
            isBlur: false,
            isFocus: false,
        };
        this.setSearchTerm = this.setSearchTerm.bind(this);
        this.onSelect = this.onSelect.bind(this);

        this.box = React.createRef();
        this.input = React.createRef();
        this.isOpen = this.isOpen.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentDidMount() {
        // Adding a click event listener
        // document.addEventListener("click", this.handleOutsideClick);
        if (this.props.value) {
            this.setState({
                searchTerm: this.props.value,
            });
        }
    }

    handleOutsideClick = (event) => {
        if (
            this.box &&
            this.box.current &&
            !this.box.current.contains(event.target)
        ) {
            this.onSelect(this.props.value);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.addressSuggestions !== this.props.addressSuggestions) {
            this.setState({
                addressSuggestions: [...this.props.addressSuggestions],
            });
        }

        if (prevState.isBlur !== this.state.isBlur) {
            if (this.state.isBlur && !this.props.value) {
                if (
                    this.props.addressSuggestions &&
                    this.props.addressSuggestions.length
                ) {
                    this.props.onFormChange("");
                }
            }
        }

        if (prevProps.value !== this.props.value) {
            if (this.props.value) {
                this.setState({
                    searchTerm: this.props.value,
                });
            }
        }
    }

    setSearchTerm = debounce((value) => {
        if (value !== this.state.searchTerm) {
            this.setState({ searchTerm: value || "" });
            this.props.autoFillAddress({ address_query: value });
            this.props.onFormChange("");
        }
    }, 1000);

    onSelect(suggestion) {
        let address_query = suggestion;
        this.setState({
            searchTerm: address_query,
            addressSuggestions: null,
            isBlur: false,
        });
        this.props.onFormChange(address_query);
        this.input.current.value = address_query || "";
    }

    isOpen() {
        let { autoFillRequest } = this.props;
        let { addressSuggestions, isFocus } = this.state;
        return isFocus || addressSuggestions || autoFillRequest;
    }

    render() {
        let { autoFillRequest, classes } = this.props;
        let { searchTerm, addressSuggestions } = this.state;
        return (
            <div
                className={classnames("address-input-select", classes, {
                    isOpen: this.isOpen(),
                })}
                ref={this.box}
            >
                <Input
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    defaultValue={searchTerm}
                    innerRef={this.input}
                    onChange={(e) => {
                        this.setSearchTerm(e.target.value);
                    }}
                    disabled={this.props.disabled}
                    onBlur={(e) => {
                        if (e.target.value.length === 0) {
                            this.setSearchTerm(e.target.value);
                        }
                    }}
                    // onBlur={() =>
                    //     this.setState({ isBlur: true, isFocus: false })
                    // }
                    // onFocus={() =>
                    //     this.setState({ isBlur: false, isFocus: true })
                    // }
                    autoComplete="off"
                />
                {/* {this.props.value ? null : (
                    <i className="fas fa-exclamation exclamation"></i>
                )} */}
                <div className="address-suggestion w-100">
                    {autoFillRequest ? (
                        <div className="address-spanture w-100">
                            <i className="fad fa-spinner fa-spin mr-2"></i>
                            Loading...
                        </div>
                    ) : null}
                    {addressSuggestions && addressSuggestions.length ? (
                        <>
                            <div className="address-spanture w-100">
                                {" "}
                                SELECT FROM BELOW{" "}
                            </div>
                            {addressSuggestions.map((suggestion, i) => {
                                return (
                                    <button
                                        type="button"
                                        key={`addr-suggestion${i}`}
                                        className="btn option-button"
                                        onClick={(e) =>
                                            this.onSelect(suggestion)
                                        }
                                    >
                                        <i className="fas fa-map-marker-alt mr-2"></i>
                                        <span>{suggestion}</span>
                                    </button>
                                );
                            })}
                        </>
                    ) : null}

                    {searchTerm &&
                    !autoFillRequest &&
                    addressSuggestions &&
                    addressSuggestions.length === 0 ? (
                        <div className="address-spanture w-100 text-danger">
                            We could not find your address
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        addressSuggestions: state.confirm.addressSuggestions,
        autoFillRequest: state.confirm.autoFillRequest,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        autoFillAddress: (params) => dispatch(autoFillAddress(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressInput);
