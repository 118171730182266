import React, { useEffect, useState } from "react";
import currency from "currency.js";
import { Card, Button } from "reactstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import StripeCheckoutForm from "../Forms/StripeCheckoutForm";
import PageTitle from "../Common/PageTitle";
import EssentialPricing from "./EssentialPricing";
import PaymentDetail from "./PaymentDetail";

import { STRIPE_API_KEY } from "../../utils/constant";

const stripePromise = loadStripe(STRIPE_API_KEY);

// Subscription discount  CONVENIENCE FEE
const getCalcInfo = (subscription, discount) => {
    const discountSub = currency(subscription).subtract(discount || 0).value;

    // 公式 =1.49-(白色块-49)*0.00496
    const convenienceFee = currency(1.49).subtract(
        currency(subscription).subtract(49).multiply(0.00496)
    ).value;

    return {
        discountSub,
        convenienceFee,
    };
};

function PaymentForm(props) {
    const { t } = useTranslation();
    const [type, setType] = useState(true);
    const [subscription, setSubscription] = useState("");
    const product = props?.onboarding?.rushable_plan;
    const { discountSub, convenienceFee } = getCalcInfo(
        subscription,
        product?.subscription_discount
    );
    useEffect(() => {
        setSubscription(currency(product?.subscription_amount));
    }, [product]);

    return (
        <Card className="form-card">
            <PageTitle styles={{ padding: "0 7px" }}>
                {type ? t("plan.title") : t("plan.title2")}
            </PageTitle>
            {type ? (
                <div className="form-p-x">
                    <EssentialPricing
                        product={product}
                        discountSub={discountSub}
                        convenienceFee={convenienceFee}
                        subscription={subscription}
                        setSubscription={setSubscription}
                    />
                    <PaymentDetail product={product} />
                    <Button
                        color="primary"
                        block
                        className="mt-5"
                        size="lg"
                        type="submit"
                        onClick={() => setType(false)}
                    >
                        {t("plan.looks_great")}
                    </Button>
                </div>
            ) : (
                <Elements stripe={stripePromise}>
                    <StripeCheckoutForm
                        goBack={() => setType(true)}
                        {...props}
                        amount={subscription}
                    />
                </Elements>
            )}
        </Card>
    );
}

export default PaymentForm;
