import React from "react";
import { Provider } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store/store";
import httpService from "./utils/api.interceptors";
import MainLayoutRoute from "./pages/layouts/MainLayoutRoute";
import Terms from "./pages/terms";
import Policy from "./pages/policy";

import Landing from "./pages/landing";
import PageNotFound from "./pages/PageNotFound";

httpService.setupInterceptors(store, history);

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <MainLayoutRoute path="/onboard/:src" component={Landing} />

          <Route path="/privacy-policy" component={Policy} />
          <Route path="/terms-of-service" component={Terms} />
          <MainLayoutRoute path="*" component={PageNotFound} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
