import React from "react";
import { connect } from "react-redux";
import BankInformationForm from "../../components/Payout/BankInformationForm";
import { postPayout } from "../../store/actions/confirm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_API_KEY } from "../../utils/constant";
const stripePromise = loadStripe(STRIPE_API_KEY);

class PayoutIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.confirmBankAccount = this.confirmBankAccount.bind(this);
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.confirmPayoutRequest !== this.props.confirmPayoutRequest) {
    //   if (!this.props.confirmPayoutRequest) {
    //     if (this.props.confirmPayoutSuccess) {
    //       let { src } = this.props.match.params;
    //       this.props.history.push({
    //         pathname: `/onboard/${src}/complete`,
    //       });
    //     }
    //   }
    // }
  }

  async confirmBankAccount(bankAccount) {
    let { src } = this.props.match.params;
    let isSuccess = await this.props.postPayout({
      src,
      bank_account_token: bankAccount.id,
    });
    if (isSuccess) {
      this.props.history.push({
        pathname: `/onboard/${src}/welcome-call`,
      });
    }
  }

  render() {
    return (
      <>
        <Elements stripe={stripePromise}>
          <BankInformationForm
            {...this.props}
            data={this.state.formData}
            onFormChange={this.onFormChange}
            confirmBankAccount={this.confirmBankAccount}
            confirmPayoutError={this.props.confirmPayoutError}
            confirmPayoutRequest={this.props.confirmPayoutRequest}
            togglePolicyModal={this.togglePolicyModal}
            toggleTermsModal={this.toggleTermsModal}
          />
        </Elements>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    onboarding: state.auth.onboarding,
    confirmPayoutError: state.confirm.confirmPayoutError,
    confirmPayoutRequest: state.confirm.confirmPayoutRequest,
    confirmPayoutSuccess: state.confirm.confirmPayoutSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { postPayout: (params) => dispatch(postPayout(params)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutIndex);
