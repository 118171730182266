import React from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "../Common/PageTitle";

export default function QuestionnaireTitle() {
    const { t } = useTranslation();
    return (
        <PageTitle>
            <div className="qn-title-text">{t("questionnaire.title")}</div>
            <div className="qn-title-text">{t("questionnaire.title2")}</div>
        </PageTitle>
    );
}
