import { addSeconds } from "date-fns";
import React from "react";
import { connect } from "react-redux";
import Location from "../../components/Location/Location";
import { postLocation } from "../../store/actions/confirm";
import {
    formatDigitOnly,
    formatPhoneNumber,
    onPhoneKeyDown,
    onPhoneKeyUp,
} from "../../utils/digit";

class LocationIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
        };
        this.renderFormData = this.renderFormData.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onPhoneKeyUp = this.onPhoneKeyUp.bind(this);
    }

    componentDidMount() {
        this.renderFormData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.onboarding !== this.props.onboarding) {
            this.renderFormData();
        }
    }

    renderFormData() {
        let onboarding = this.props.onboarding;
        if (onboarding) {
            if (onboarding.signup_form) {
                let { location_address } = onboarding.signup_form;
                let address = { location_address: "", location_address_2: "" };
                if (location_address) {
                    address = {
                        location_address:
                            location_address.line_1 +
                            " " +
                            location_address.city +
                            " " +
                            location_address.state +
                            " " +
                            location_address.zipcode,
                        location_address_2: location_address.line_2,
                    };
                }

                let formData = {
                    ...onboarding.signup_form,
                    location_phone: formatPhoneNumber(
                        onboarding.signup_form.location_phone
                    ),
                    ...address,
                };
                this.setState({
                    formData,
                });
            }
        }
    }
    onFormChange(name, val) {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val,
            },
        });
    }

    onSubmit(e) {
        e.preventDefault();
        let { src } = this.props.match.params;
        let params = {
            ...this.state.formData,
            src,
            location_phone: formatDigitOnly(
                this.state.formData.location_phone ?? ""
            ),
        };
        this.props.postLocation(params);
    }

    onPhoneKeyUp(e) {
        var ph = onPhoneKeyUp(e, e.target.value);
        let formData = {
            ...this.state.formData,
            location_phone: ph,
        };
        this.setState({ formData });
    }

    render() {
        return (
            <Location
                {...this.props}
                data={this.state.formData}
                onFormChange={this.onFormChange}
                onSubmit={this.onSubmit}
                confirmLocationError={this.props.confirmLocationError}
                confirmLocationRequest={this.props.confirmLocationRequest}
                onPhoneKeyDown={onPhoneKeyDown}
                onPhoneKeyUp={this.onPhoneKeyUp}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        onboarding: state.auth.onboarding,
        confirmLocationError: state.confirm.confirmLocationError,
        confirmLocationRequest: state.confirm.confirmLocationRequest,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { postLocation: (params) => dispatch(postLocation(params)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationIndex);
