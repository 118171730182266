import { DAYS } from "./constant";
import moment from "moment";
export const readableBytes = (bytes) => {
  var i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
};

export const filterFromObjectArray = (options, val, valueKey) => {
  let selected = options.filter((item) => {
    // eslint-disable-next-line eqeqeq
    return item[valueKey] == val;
  });
  if (!selected.length) return null;
  return selected[0];
};

export const filterDayHours = (openHours, day) => {
  let dayHours = openHours.filter((item) => {
    return item.day === day;
  });
  dayHours.sort((a, b) => {
    return moment(a.from, "HH:mm:ss") - moment(b.from, "HH:mm:ss");
  });
  return dayHours;
};

export const returnHoursInDays = (openHours) => {
  let hoursInDays = {};
  DAYS.forEach((day) => {
    let dayHours = filterDayHours(openHours, day.abbr);
    hoursInDays = {
      ...hoursInDays,
      [day.abbr]: dayHours,
    };
  });
  return hoursInDays;
};

export const returnOpenHours = (days) => {
  let openHours = [];
  DAYS.forEach((day) => {
    openHours = [...openHours, ...days[day.abbr]];
  });
  return openHours;
};

export const validateOpenHours = (hours) => {
  let isValid = true;
  hours.forEach((hour, key) => {
    let from = moment(hour.from, "HH:mm:ss");
    let to = moment(hour.to, "HH:mm:ss");
    if (from > to) {
      isValid = false;
    }
  });
  return isValid;
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const titleCase = (str) => {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

export const prorationCost = (periodEnd, periodStart, requestTime, quantity, planPrice) => {
  return (periodEnd - requestTime) / (periodEnd - periodStart) * quantity * planPrice;
}

export const roleLevel = (role) => {
  let role_level = [
    {
      value: "master",
      level: 1
    },
    {
      value: "lv2_support",
      level: 2
    },
    {
      value: "lv1_support",
      level: 3
    },
    {
      value: "menu_entry",
      level: 4
    },
    {
      value: "brand_admin",
      level: 11
    },
    {
      value: "location_owner",
      level: 12
    },
    {
      value: "manager",
      level: 13
    },
    {
      value: "location_staff",
      level: 14
    },
    {
      value: "channel_partner",
      level: 15
    },
    {
      value: "coordinator",
      level: 16
    },
  ];
  let item = role_level.filter((item) => {
    // eslint-disable-next-line eqeqeq
    return item["value"] == role;
  });
  if (!item.length) return null;
  return item[0].level;
}

export const ccCardIcon = (cardBrand) => {
  let cardIcon = null;
  switch (cardBrand) {
    case "visa":
      cardIcon = "fab fa-cc-visa";
      break;
    case "amex":
      cardIcon = "fab fa-cc-amex";
      break;
    case "diners":
      cardIcon = "fab fa-cc-diners-club";
      break;
    case "discover":
      cardIcon = "fab fa-cc-discover";
      break;
    case "jcb":
      cardIcon = "fab fa-cc-jcb";
      break;
    case "master":
    case "mastercard":
      cardIcon = "fab fa-cc-mastercard";
      break;
    case "unionpay":
      cardIcon = "fad fa-credit-card";
      break;
    default:
      cardIcon = "fad fa-credit-card";
      break;
  }
  return cardIcon;
}

export const proAddonIcon = (name) => {
  let cardIcon = null;
  switch (name) {
    case "Multilingual Menu":
      cardIcon = "multilingual_menu";
      break;
    case "Curbside Order":
      cardIcon = "curbside_order";
      break;
    case "Order Reminder":
      cardIcon = "order_reminder";
      break;
    case "Orders Hub":
      cardIcon = "orders_hub";
      break;
    default:
      cardIcon = null;
      break;
  }
  return cardIcon;
}



export const  deepClone = (item) => {
  if (!item) { return item; } // null, undefined values check

  var types = [ Number, String, Boolean ], 
      result;

  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  types.forEach(function(type) {
      if (item instanceof type) {
          result = type( item );
      }
  });

  if (typeof result == "undefined") {
      if (Object.prototype.toString.call( item ) === "[object Array]") {
          result = [];
          item.forEach(function(child, index, array) { 
              result[index] = deepClone( child );
          });
      } else if (typeof item == "object") {
          // testing that this is DOM
          if (item.nodeType && typeof item.cloneNode == "function") {
              result = item.cloneNode( true );    
          } else if (!item.prototype) { // check that this is a literal
              if (item instanceof Date) {
                  result = new Date(item);
              } else {
                  // it is an object literal
                  result = {};
                  for (var i in item) {
                      result[i] = deepClone( item[i] );
                  }
              }
          } else {
              // depending what you would like here,
              // just keep the reference, or create new object
              if (false && item.constructor) {
                  // would not advice to do that, reason? Read below
                  result = new item.constructor();
              } else {
                  result = item;
              }
          }
      } else {
          result = item;
      }
  }

  return result;
}