import React from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import QuestionnaireCard from "./QuestionnaireCard";
import QuestionnaireSelect from "./QuestionnaireSelect";
import AddressInput from "../Forms/AddressInput";

export default function QuestionnaireForm({
    formData,
    openDialog,
    handleUpdate,
    formDataChange,
    uploadFile,
    deleteFile,
    address,
    handleAddress2Editing,
}) {
    const { t } = useTranslation();
    const {
        website_preference,
        has_existing_domain,
        self_domain,
        delegate_dns_or_website,
        has_gmb_ownership,
        need_help_regain_gmb,
        delegate_gmb_update,
        menu_source,
        menu_link,
        menu_work_notes,
        files,
        shipping_destination,
        shipping_address_query,
        shipping_address_line_2,
    } = formData;
    const addressName = address
        ? `${address.line_1 ? address.line_1 + " " : ""}${
              address.city ? address.city + " " : ""
          }${address.line_2 ? address.line_2 + " " : ""}${
              address.state ? address.state + " " : ""
          }${address.zipcode ? address.zipcode + " " : ""}`
        : "Ship to restaurant";

    return (
        <div className="qn-form">
            <QuestionnaireCard
                title={t("questionnaire.formData.questionOneTitle")}
            >
                <QuestionnaireSelect
                    question={t("questionnaire.formData.question1.q")}
                    answers={[
                        t("questionnaire.formData.question1.a1"),
                        t("questionnaire.formData.question1.a2"),
                    ]}
                    answersType={["full_website", "ordering_page_only"]}
                    selectNum={
                        website_preference !== null
                            ? website_preference === "full_website"
                                ? 0
                                : 1
                            : null
                    }
                    handleUpdate={handleUpdate}
                    type="website_preference"
                />

                {website_preference === "full_website" && (
                    <>
                        <div className="qn-form-blank" />
                        <QuestionnaireSelect
                            question={t("questionnaire.formData.question2.q")}
                            answers={[
                                t("questionnaire.formData.question2.a1"),
                                t("questionnaire.formData.question2.a2"),
                            ]}
                            selectNum={
                                typeof has_existing_domain === "number"
                                    ? has_existing_domain === 1
                                        ? 0
                                        : 1
                                    : null
                            }
                            inputs={[
                                {
                                    num: 0,
                                    placeholder: t(
                                        "questionnaire.formData.question2.p"
                                    ),
                                    type: "self_domain",
                                    value: self_domain,
                                },
                            ]}
                            handleUpdate={handleUpdate}
                            type="has_existing_domain"
                        />
                    </>
                )}
                {(website_preference === "ordering_page_only" ||
                    has_existing_domain === 1) && (
                    <>
                        <div className="qn-form-blank" />
                        <QuestionnaireSelect
                            question={t("questionnaire.formData.question3.q")}
                            answers={[
                                t("questionnaire.formData.question3.a1"),
                                t("questionnaire.formData.question3.a2"),
                            ]}
                            selectNum={
                                typeof delegate_dns_or_website === "number"
                                    ? delegate_dns_or_website === 1
                                        ? 0
                                        : 1
                                    : null
                            }
                            handleUpdate={handleUpdate}
                            type="delegate_dns_or_website"
                        />
                    </>
                )}
            </QuestionnaireCard>
            <QuestionnaireCard
                title={t("questionnaire.formData.questionTwoTitle")}
            >
                <QuestionnaireSelect
                    question={t("questionnaire.formData.question4.q")}
                    answers={[
                        t("questionnaire.formData.question4.a1"),
                        t("questionnaire.formData.question4.a2"),
                    ]}
                    selectNum={
                        typeof has_gmb_ownership === "number"
                            ? has_gmb_ownership === 1
                                ? 0
                                : 1
                            : null
                    }
                    showWhy
                    openDialog={openDialog}
                    handleUpdate={handleUpdate}
                    type="has_gmb_ownership"
                />
                {has_gmb_ownership === 0 && (
                    <>
                        <div className="qn-form-blank" />
                        <QuestionnaireSelect
                            question={t("questionnaire.formData.question5.q")}
                            answers={[
                                t("questionnaire.formData.question5.a1"),
                                t("questionnaire.formData.question5.a2"),
                            ]}
                            answersType={["rushable_claiming", "self_claim"]}
                            selectNum={
                                need_help_regain_gmb !== null
                                    ? need_help_regain_gmb ===
                                      "rushable_claiming"
                                        ? 0
                                        : 1
                                    : null
                            }
                            handleUpdate={handleUpdate}
                            type="need_help_regain_gmb"
                        />
                    </>
                )}

                <div className="qn-form-blank" />
                <QuestionnaireSelect
                    question={t("questionnaire.formData.question6.q")}
                    answers={[
                        t("questionnaire.formData.question6.a1"),
                        t("questionnaire.formData.question6.a2"),
                    ]}
                    // answersType={["self_claim", "rushable_claiming"]}
                    selectNum={
                        typeof delegate_gmb_update === "number"
                            ? delegate_gmb_update === 1
                                ? 0
                                : 1
                            : null
                    }
                    handleUpdate={handleUpdate}
                    type="delegate_gmb_update"
                />
            </QuestionnaireCard>
            <QuestionnaireCard
                title={t("questionnaire.formData.questionThreeTitle")}
            >
                <QuestionnaireSelect
                    question={t("questionnaire.formData.question7.q")}
                    answers={[
                        t("questionnaire.formData.question7.a1"),
                        t("questionnaire.formData.question7.a2"),
                    ]}
                    answersType={["file", "link"]}
                    selectNum={
                        typeof menu_source === "string"
                            ? menu_source === "file"
                                ? 0
                                : 1
                            : null
                    }
                    handleUpdate={handleUpdate}
                    type="menu_source"
                />
                <div className="qn-form-line" />
                {menu_source === "file" && (
                    <>
                        <div className="qn-form-title">
                            {t("questionnaire.formData.uploadTitle")}
                        </div>
                        {files.map((file) => (
                            <div className="qn-form-file" key={file.id}>
                                <div className="form-file-name">
                                    <i className="fas fa-file" />
                                    {file.file_name}
                                </div>
                                <div
                                    className="form-file-delete"
                                    onClick={() => deleteFile(file.id)}
                                >
                                    {(file.deleting && file.id !== "loading") ||
                                    file.id === "loading" ? (
                                        <i className="fad fa-spinner fa-spin" />
                                    ) : (
                                        <i className="far fa-trash-alt" />
                                    )}
                                </div>
                            </div>
                        ))}
                        <div className="qn-form-upload">
                            <div className="form-upload-title">
                                <i className="far fa-file-upload"></i>
                                {t("questionnaire.formData.uploadTip")}
                            </div>
                            <div className="form-upload-tip">
                                (pdf, jpg, jpeg, png, doc, docx, xls, xlsx)
                            </div>
                            <Input
                                type="file"
                                className="form-upload-input"
                                accept="application/pdf,application/vnd.ms-excel,image/*"
                                multiple={false}
                                onChange={uploadFile}
                            />
                        </div>
                    </>
                )}
                {menu_source === "link" && (
                    <>
                        <div className="qn-form-title">
                            {t("questionnaire.formData.linkTitle")}
                        </div>
                        <Input
                            defaultValue={menu_link}
                            className="qn-form-input"
                            placeholder={t(
                                "questionnaire.formData.linkPlaceholder"
                            )}
                            onBlur={(e) =>
                                handleUpdate("menu_link", e.target.value)
                            }
                        />
                    </>
                )}
                <div className="qn-form-title">
                    {t("questionnaire.formData.notesTitle")}
                </div>
                <Input
                    defaultValue={menu_work_notes}
                    type="textarea"
                    style={{
                        resize: "none",
                        height: 81,
                    }}
                    onBlur={(e) =>
                        handleUpdate("menu_work_notes", e.target.value)
                    }
                />
            </QuestionnaireCard>
            <QuestionnaireCard
                title={t("questionnaire.formData.questionFourTitle")}
            >
                <QuestionnaireSelect
                    question={t("questionnaire.formData.question8.q")}
                    answers={[
                        addressName,
                        t("questionnaire.formData.question8.a2"),
                    ]}
                    answersType={["restaurant", "other"]}
                    selectNum={
                        shipping_destination !== null
                            ? shipping_destination === "restaurant"
                                ? 0
                                : 1
                            : null
                    }
                    handleUpdate={handleUpdate}
                    type="shipping_destination"
                />
                {shipping_destination === "other" && (
                    <div>
                        <div className="qn-form-title">
                            {t("questionnaire.formData.location_address")}
                        </div>
                        <AddressInput
                            name="loc_add_query"
                            classes="loc_add_query"
                            value={shipping_address_query}
                            placeholder={t(
                                "questionnaire.formData.question8.p"
                            )}
                            onFormChange={(value) => {
                                // handleUpdate("shipping_address_query", value);
                                if (value !== shipping_address_query) {
                                    // setAddressValue(value);
                                    handleUpdate(
                                        "shipping_address_query",
                                        value
                                    );
                                }
                            }}
                        />
                        <div className="qn-form-title">
                            {t("brand.location_address_2")}
                        </div>
                        <Input
                            value={shipping_address_line_2}
                            type="text"
                            onChange={(e) =>
                                formDataChange(
                                    "shipping_address_line_2",
                                    e.target.value
                                )
                            }
                            onFocus={() => handleAddress2Editing(true)}
                            onBlur={(e) => {
                                if (e.target.value.length > 0) {
                                    handleUpdate(
                                        "shipping_address_line_2",
                                        e.target.value
                                    );
                                }
                                handleAddress2Editing(false);
                            }}
                        />
                    </div>
                )}
            </QuestionnaireCard>
        </div>
    );
}
