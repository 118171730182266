import React from "react";
import { connect } from "react-redux";
import FailureForm from "../../components/Failure/FailureForm";

class FailureIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
    this.check = this.check.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  check() {
    this.props.history.push({
      pathname: `/onboard/${this.props.match.params.src}/business`,
    });
  }

  render() {
    return (
      <FailureForm onboarding={this.props.onboarding} check={this.check} />
    );
  }
}

const mapStateToProps = (state) => {
  return { onboarding: state.auth.onboarding };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FailureIndex);
