import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { english } from "./locals/english";
import { chinese } from "./locals/chinese";
import { vietnamese } from "./locals/vietnamese";
import { spanish } from "./locals/spanish";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    english: {
        translation: english,
    },
    chinese: {
        translation: chinese,
    },
    spanish: {
        translation: spanish,
    },
    // vietnamese: {
    //   translation: vietnamese,
    // },
};

i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.language || "english",
    fallbackLng: "english",
    interpolation: {
        escapeValue: false,
    },
});

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "english",
        lng: "english",
        debug: true,
        resources: resources,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
