import React from "react";
import { Card, Form } from "reactstrap";

import { useTranslation } from "react-i18next";
import PageTitle from "../Common/PageTitle";

function VerificationForm(props) {
    const { t } = useTranslation();

    return (
        <Card className="form-card">
            <Form>
                {" "}
                <PageTitle>{t("failure.title")}</PageTitle>
                <span className="verification m-auto d-flex"></span>
                <div className="f-18 bold text-center">
                    <span className="loading-text">
                        {t("failure.verify")} {props.loadingPoints}
                    </span>
                </div>
            </Form>
        </Card>
    );
}

export default VerificationForm;
