import React from "react";
import { InlineWidget, CalendlyEventListener } from "react-calendly";
import { useTranslation } from "react-i18next";

const CalendlyInline = (props) => {
    // get booking link based on lang.
    const { welcomeCallLinks } = props;
    const { i18n } = useTranslation();
    let link = '';
    switch (i18n.language) {
        case "chinese":
            link = welcomeCallLinks.chinese;
            break;
        case "english":
            link = welcomeCallLinks.english;
            break;
        case "spanish":
            link = welcomeCallLinks.spanish;
            break;
        default:
            break;
    }

    const onEventScheduled = (e) => {
        props.changeStep("confirm_welcome_call");
    };

    return (
        <div className="calendly-outline">
            <CalendlyEventListener onEventScheduled={onEventScheduled}>
                <InlineWidget
                    url={link ?? ""}
                    styles={{
                        height: "100%",
                    }}
                    prefill={{
                        name: props.repName ?? "",
                        email: props.repEmail ?? "",
                        customAnswers: {
                            a1: props.locationPhone ?? "",
                            a2: props.brandName ?? "",
                        },
                    }}
                />
            </CalendlyEventListener>
        </div>
    );
};

export default CalendlyInline;
