import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import PageTitle from "../Common/PageTitle";

export default function CompletedListPage({ onboarding }) {
    const { t } = useTranslation();
    const { onboarding_tasks } = onboarding;
    console.log(onboarding, "onboarding");
    return (
        <div className="form-card card" style={{ maxWidth: 400 }}>
            <PageTitle>
                <div className="completed-title-text1">
                    {t("completed_list.title1")}
                </div>
                <div className="completed-title-text2">
                    {t("completed_list.title2")}
                </div>
            </PageTitle>
            <div className="completed-list">
                {onboarding_tasks && onboarding_tasks.length > 0
                    ? onboarding_tasks.map((item) => {
                          return (
                              <div className="completed-list-item">
                                  <div className="completed-list-item-text">
                                      {item.name}
                                  </div>
                                  <div className="completed-list-icon">
                                      {item.completed_at ? (
                                          <i className="fas fa-check-circle" />
                                      ) : (
                                          <div className="icon-no" />
                                      )}
                                  </div>
                              </div>
                          );
                      })
                    : null}
            </div>
            <div className="completed-button">
                <Button
                    color="primary"
                    block
                    className="mt-0"
                    size="lg"
                    type="submit"
                    onClick={() => window.open("https://admin.rushable.io")}
                >
                    {t("completed_list.button")}
                </Button>
            </div>
        </div>
    );
}
