import React from "react";
import { connect } from "react-redux";
import Sendcode from "../../components/Sendcode/Sendcode";
import { login, sendcode } from "../../store/actions/auth";

class SendcodeIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verification_code: "",
    };
    this.onChange = this.onChange.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    // this.props.history.push('/');
  }

  onChange(verification_code) {
    this.setState({ verification_code });
    let phone = this.props.onboarding.signup_form.crm_representative.phone;
    if (verification_code.length === 4) {
      this.props.login({
        src: this.props.match.params.src,
        phone,
        verification_code,
      });
    }
  }

  resendCode() {
    let phone = this.props.onboarding.signup_form.crm_representative.phone;
    this.props.sendcode({
      src: this.props.match.params.src,
      phone,
      rep_agrees_to_terms: true,
      resend: true,
    });
  }

  goBack() {
    this.props.history.push({
      pathname: `/onboard/${this.props.match.params.src}/login`,
    });
  }

  render() {
    if (!this.props.onboarding) return null;
    let { signup_form } = this.props.onboarding;
    if (!signup_form) return null;
    let isReturnLogin = false;
    let activeLink = this.props.location.pathname;
    isReturnLogin = signup_form.current_step > 1 ? true : false;

    return (
      <Sendcode
        {...this.props}
        data={this.state}
        onChange={this.onChange}
        resendCode={this.resendCode}
        goBack={this.goBack}
        isReturnLogin={isReturnLogin}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    onboarding: state.auth.onboarding,
    sendCodeRequest: state.auth.sendCodeRequest,
    sendCodeSuccessMsg: state.auth.sendCodeSuccessMsg,
    sendCodeError: state.auth.sendCodeError,
    loginError: state.auth.loginError,
    loginRequest: state.auth.loginRequest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => dispatch(login(params)),
    sendcode: (params) => dispatch(sendcode(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendcodeIndex);
