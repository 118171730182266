import React from "react";
import Login from "../../components/Login/Login";
import ReturnLogin from "../../components/Login/ReturnLogin";
import { connect } from "react-redux";
import { sendcode } from "../../store/actions/auth";
import { postRepInfo } from "../../store/actions/confirm";

class LoginIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                rep_agrees_to_terms: "",
            },
        };
        this.renderFormData = this.renderFormData.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.sendCode = this.sendCode.bind(this);
    }

    componentDidMount() {
        this.renderFormData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.onboarding !== this.props.onboarding) {
            this.renderFormData();
        }
    }

    renderFormData() {
        let { onboarding } = this.props;
        if (onboarding) {
            if (onboarding.signup_form) {
                if (onboarding.signup_form.crm_representative) {
                    let formData = {
                        ...onboarding.signup_form.crm_representative,
                    };
                    this.setState({
                        formData,
                    });
                }
            }
        }
    }

    onFormChange(name, val) {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val,
            },
        });
    }

    onSubmit(e) {
        e.preventDefault();
        let { src } = this.props.match.params;
        this.props.postRepInfo({ ...this.state.formData, src });
    }

    sendCode(e) {
        e.preventDefault();
        let { src } = this.props.match.params;
        let { onboarding } = this.props;
        let { signup_form } = onboarding;
        let { crm_representative } = signup_form;
        let { phone } = crm_representative;
        this.props.sendcode({
            src: src ?? "",
            phone: phone ?? "",
            rep_agrees_to_terms: true,
            resend: false,
        });
    }

    render() {
        if (!this.props.onboarding) return null;
        let { signup_form } = this.props.onboarding;
        if (!signup_form) return null;

        if (!signup_form.current_step) {
            return (
                <Login
                    {...this.props}
                    data={this.state.formData}
                    closer={this.state.closer}
                    onFormChange={this.onFormChange}
                    onSubmit={this.onSubmit}
                />
            );
        }

        return <ReturnLogin {...this.props} onSubmit={this.sendCode} />;
    }
}

const mapStateToProps = (state) => {
    return {
        onboarding: state.auth.onboarding,
        confirmRepInfoError: state.confirm.confirmRepInfoError,
        confirmRepInfoRequest: state.confirm.confirmRepInfoRequest,
        sendCodeRequest: state.auth.sendCodeRequest,
        sendCodeError: state.auth.sendCodeError,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        postRepInfo: (params) => dispatch(postRepInfo(params)),
        sendcode: (params) => dispatch(sendcode(params)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginIndex);
