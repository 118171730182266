export const english = {
    common: {
        questions: "Questions?",
        call_me: "CALL ME",
    },
    rep: {
        title_1: "Hi, my name is ",
        title_2:
            ",  I will walk you through this onboarding process. Ready to go?",
        first_name: "First name",
        last_name: "Last name",
        // change
        your_email: "Email",
        // change
        cell_phone: "Cellphone",
        password: "Create your password",
        // change
        password_confirmed: "Confirm password",
        accept: "I accept the",
        and: "and",
        of_rushable: "of Rushable.",
        terms: "Terms of Service",
        policy: "Privacy Policy",
        submit: "LET’S DO THIS",
    },
    verify: {
        title_first_time:
            "I just sent you a verification code to your cellphone, please input the code.",
        title_continue:
            "Great! Let’s first verify your identity. Please input the code sent to your cellphone ending in ",
        title_continue_2: "",
        resend: "Resend",
        go_back: "GO BACK",
    },
    brand: {
        title: "Next, please confirm that we have the correct information for your restaurant.",
        brand_name: "Restaurant name",
        location_nickname: "Location label",
        location_address: "Address line 1",
        location_address_2: "Suite, Unit, Floor, etc (👈important)",
        restaurant_email: "Restaurant email",
        restaurant_phone: "Restaurant phone",
        tax: "Sales tax rate",
        submit: "LOOKS ALL GOOD",
    },
    business: {
        title: "Next, I will need to verify the legal registration of your business entity.",
        business_name: "Business legal registration name",
        dba: "DBA (optional)",
        business_phone: "Business phone",
        ein: "Business EIN",
        address: "Registration address",
        city: "City",
        state: "State",
        zipcode: "Zipcode",
        go_back: "GO BACK",
        tips: "☝️ Must be 100% accurate including",
        tips_letter_case: " letter case ",
        tips_and: "and",
        tips_symbols: " symbols",
        tips_leftover:
            '. If the "legal business name" and "EIN" do not match, your payout will be held until further verification with the IRS.',
        confirm: "VERIFY NOW",
        confirming: "VERIFYING",
    },
    owner: {
        title: "This may get a little personal, but your information will be safe with our privacy policy. We just need to submit these information for screening.",
        owner_first_name: "Owner’s legal first name",
        owner_last_name: "Owner’s legal last name",
        owner_25: "Owner of 25% and more?",
        owner_phone: "Phone contact",
        dob: "Date of birth",
        last4: "Last 4 digit of SSN",
        state: "State",
        zipcode: "Zipcode",
        address: "Address",
        go_back: "GO BACK",
        confirm: "CONFIRM",
        city: "City",
    },
    plan: {
        title: "Almost there! Please review the subscription",
        title2: "Now let’s setup your payment profile",
        monthly_subscription: "Monthly Subscription",
        restaurant_pay: "Restaurant pay",
        customer_pay: "Customer pay",
        other_title: "Other Fee Schedule",
        account_setup: "Account setup",
        branded_website: "Branded website",
        on_demand_delivery: "On-demand delivery",
        per_month: "per month",
        per_order: "per order",
        at_launch: "at launch",
        after_launch: "90 days after launch",
        per_dispatch: "per dispatch",
        looks_great: "LOOKS GREAT",
        pay_title: "Setup Payment Profile",
        go_back: "GO BACK",
        pay: "CONFIRM",
        tips: "ℹ️ You will see a $0.50 temporary charge on the card for verification, but don’t worry, it will go away in a few days.",
    },
    processor: {
        // change
        title: "To process online orders, we've partnered with Stripe, the most popular and secure credit card processor in the world.",
        stripe: "Stripe",
        // change
        stripe_title: "Payment Processing",
        // change
        stripe_text:
            "Flat 2.90% + 30¢ rate on all accepted cards. Additional fees required for international cards. You can find out more details on",
        companies_title: "Stripe is behind all these companies",
        // change add
        companies_text:
            "Your payment processing is backed and secured by the same company that handles the transaction processing for these companies too.",
        go_back: "GO BACK",
        // change
        confirm: "NEXT STEP",
    },
    terms: {
        // change
        title: "We’ve also put together the Privacy Policy and Terms of Use to be displayed to your customers and to protect your rights.",
        terms: "Terms of Use",
        policy: "Privacy Policy",
        preview: "Preview",
        agree_text:
            "I have reviewed and agree to display this pre-set Privacy Policy and Terms of Use on my website for visitors.",
        go_back: "GO BACK",
        // change
        confirm: "NEXT STEP",
    },
    bank: {
        // change
        title: "To receive your payout, please input your bank account information for deposit.",
        account_title: "Your account title",
        account_type: "Bank account type",
        account_name: "Account name",
        account_number: "Account number",
        confirm_account_number: "Confirm account number",
        routing_number: "Routing number",
        stripe: "Stripe",
        stripe_title: "Payment Processing Fee for Online Orders",
        stripe_text:
            "Flat 2.90% + 30¢ rate on all accepted cards. Additional fees required for international cards. More details on",
        terms: "Terms of Use",
        policy: "Privacy Policy",
        preview: "Preview",
        agree_text:
            "I have reviewed and agree to display this pre-set Privacy Policy and Terms of Use on my website for visitors.",
        confirm: "CONFIRM",
    },
    congratulate: {
        title1: "🙌 Awesome, you are all set.",
        title2: "Our customer success team will take over from here,it has been my pleasure! ",
        title3: "",
        title4: " ",
        to_dashboard: "GO TO DASHBOARD",
    },
    continue: {
        title1: "Hi ",
        title2: ", let’s finish what we left off, you ready?",
        submit: "LET’S DO THIS",
    },
    welcome: {
        title: "Verification completed! Please book a Welcome Call below with our support team to collaborate the onboarding process.",
        next: "What’s next?",
        next1: "Allow us 1 to 3 days to put your account together.",
        next2: "Our team will also get in touch to upload your menu.",
        next3: "We will help you set up the tablet for managing orders.",
        complete: "COMPLETE",
        tips: "Special Notes:",
    },
    failure: {
        title: "🤔 Oops, something went wrong. If you believe all the information was correctly submitted, please contact me to trouble shoot for you.",
        content:
            "🤔 Oops, something went wrong. If you believe all the information was correctly submitted, please contact our customer success team.",
        contact: "CALL ME",
        check: "DOUBLE CHECK",
        verify: "VERIFYING",
    },
    questionnaire: {
        title: "To streamline your onboarding process, ",
        title2: "please answer the questions below.",
        formData: {
            questionOneTitle: "Website & Domain Connect",
            questionTwoTitle: "Google Listing Update",
            questionThreeTitle: "Menu Submission",
            questionFourTitle: "Tablet Shipping",
            question1: {
                q: "How would you like to implement Rushable?",
                a1: "Use Rushable’s generated website",
                a2: "Add the ordering page only",
            },
            question2: {
                q: "Do you have an existing domain to use?",
                a1: "Yes, I have one.",
                a2: "No. (Rushable will purchase one for you at no cost)",
                p: "Input your domain here",
            },
            question3: {
                q: "The implementation requires DNS knowledge and website updates, would you like Rushable to help?",
                a1: "Yes, I will grant the necessary access to Rushable.",
                a2: "No, I can manage the implementation on my own.",
            },
            question4: {
                q: "Do you have ownership to your Google listing (GoogleMyBusiness profile)?",
                a1: "Yes, I have ownership.",
                a2: "No, I don’t have ownership.",
            },
            question5: {
                q: "An accurate Google listing drives more commission-free orders, would you like Rushable to regain access on your behalf?",
                a1: "Yes, I need help from Rushable.",
                a2: "No, I know how to regain the access.",
            },
            question6: {
                q: "Would you like Rushable to execute the required updates in your Google listing for you?",
                a1: "Yes, I will have Rushable to perform the updates.",
                a2: "No, I’d prefer to do it on my own.",
            },
            question7: {
                q: "How would you like to submit your menu to Rushable?",
                a1: "Upload menu file(s)",
                a2: "Input web link of the menu",
            },
            question8: {
                q: "Confirm the shipping address for your Rushable tablet:",
                a1: "",
                a2: "Ship to a different address.",
                p: "Input the address",
            },
            location_address: "New address",
            uploadTitle: "Upload menu file",
            uploadTip: "Upload File",
            linkTitle: "Input web link here",
            linkPlaceholder: "Input web link here",
            notesTitle: "Additional notes (modifiers, price change, etc)",
            buttonText: "CONFIRM",
            backText: "GO BACK",
            dialogText1:
                "Why do we ask if you have the ownership of your restaurant’s Google listing? (Aka: GoogleMyBusiness)",
            dialogText2:
                "Majority of your web traffic comes from Google, and you want this traffic to use your own commission-free ordering channel, instead of being routed to the 3rd-party platforms.",
            dialogText3:
                "With access to your Google listing, you can display your website and the commission-free online ordering option on it for your customers to choose from. A successful listing update will look like the example below:",
            dialogButton: "OKAY, I GOT IT NOW.",
        },
    },
    completed_list: {
        title1: "🙌 Awesome, you are all set.",
        title2: "Our support team will take over from here. You can come back here to monitor the onboarding progress any time!",
        q1: "Wecome call completed",
        q2: "Menu Q&A completed",
        q3: "Menu build sent for approval",
        q4: "Menu build approved by you",
        q5: "Rushable tablet shipped",
        q6: "Website & domain configured",
        q7: "Google listing updated",
        q8: "Onboarding completed",
        button: "ADMIN DASHBOARD",
    },
};
