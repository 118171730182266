import React from "react";
import {
    Card,
    FormGroup,
    Button,
    Alert,
    Label,
    Col,
    Row,
    Input,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import PageTitle from "../Common/PageTitle";

function BusinessForm(props) {
    const { t } = useTranslation();
    let { business_ein, business_legal_name } = props.data;
    const current_step = props?.onboarding?.signup_form?.current_step;
    const loading = current_step === "business_pending_verification";
    return (
        <Card className="form-card">
            <PageTitle>{t("business.title")}</PageTitle>
            <form>
                <Row form>
                    <Col>
                        <FormGroup>
                            <Label>{t("business.business_name")}</Label>
                            <Input
                                type="text"
                                name="business_legal_name"
                                placeholder="e.g. Downtown"
                                onChange={(e) =>
                                    props.onFormChange(
                                        "business_legal_name",
                                        e.target.value
                                    )
                                }
                                value={business_legal_name ?? ""}
                                required
                            />
                            <div className="business-tips">
                                {t("business.tips")}
                                <strong>
                                    {`${t("business.tips_letter_case")}`}
                                </strong>
                                {t("business.tips_and")}
                                <strong>{`${t(
                                    "business.tips_symbols"
                                )}`}</strong>
                                {t("business.tips_leftover")}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col>
                        <FormGroup>
                            <Label>{t("business.ein")}</Label>
                            <Input
                                type="tel"
                                pattern="[0-9]*"
                                name="business_ein"
                                onKeyUp={props.onBusinessEinKeyUp}
                                onKeyDown={props.onPhoneKeyDown}
                                onChange={(e) =>
                                    props.onFormChange(
                                        "business_ein",
                                        e.target.value
                                    )
                                }
                                value={business_ein ?? ""}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col>
                        {props.confirmBusinessError ? (
                            <Alert color="danger" className="mt-3">
                                {props.confirmBusinessError}
                            </Alert>
                        ) : null}
                        <Button
                            color="primary"
                            block
                            size="lg"
                            disabled={props.confirmBusinessRequest || loading}
                            onClick={(e) => props.onSubmit(e)}
                        >
                            {props.confirmBusinessRequest || loading ? (
                                <i className="fad fa-spin fa-spinner mr-2"></i>
                            ) : null}
                            {props.confirmBusinessRequest || loading
                                ? t("business.confirming")
                                : t("business.confirm")}
                        </Button>
                    </Col>
                </Row>
            </form>
        </Card>
    );
}

export default BusinessForm;
