import React from "react";
import { Card, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import CalendlyInline from "./CalendlyInline";
import PageTitle from "../Common/PageTitle";

function WelcomecallForm(props) {
    const { language, languageChange, changeStep } = props;
    const { t } = useTranslation();
    let crm_representative = props.signup_form
        ? props.signup_form.crm_representative
        : {};

    let rep_name = crm_representative
        ? (crm_representative.first_name ?? "") +
          " " +
          (crm_representative.last_name ?? "")
        : "";
    let rep_email = crm_representative ? crm_representative.email ?? "" : "";
    let brand_name = props.signup_form ? props.signup_form.brand_name : "";
    let location_phone = props.signup_form
        ? props.signup_form.crm_representative?.phone
        : "";
    const need_welcome_call = props.signup_form
        ? props.signup_form.need_welcome_call
        : 1;
    const welcome_note = props.signup_form
        ? props.signup_form.welcome_note
        : "";

    if (props.signup_form) {
        return (
            <Card className="form-card welcome-call">
                <PageTitle styles={{ padding: "0 2px" }}>
                    {t("welcome.title")}
                </PageTitle>
                {need_welcome_call === 0 && welcome_note ? (
                    <>
                        <div className="welcome-call-no">
                            <div className="welcome-call-note">
                                <div className="welcome-call-title">
                                    {t("welcome.tips")}
                                </div>
                                <div className="welcome-call-text">
                                    {welcome_note}
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button
                                color="primary"
                                block
                                className="mt-0"
                                size="lg"
                                type="submit"
                                disabled={props.changeStepRequest}
                                onClick={() =>
                                    changeStep("confirm_welcome_call")
                                }
                            >
                                {props.changeStepRequest ? (
                                    <i className="fad fa-spin fa-spinner mr-2"></i>
                                ) : null}
                                {t("welcome.complete")}
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="welcome-call-container">
                            <div className="welcome-call-language">
                                <div
                                    className={`welcome-call-language-btn mr-2 ${
                                        language === "english"
                                            ? "welcome-call-language-btn-selected"
                                            : ""
                                    }`}
                                    onClick={() => languageChange("english")}
                                >
                                    English
                                </div>
                                <div
                                    className={`welcome-call-language-btn ${
                                        language === "spanish"
                                            ? "welcome-call-language-btn-selected"
                                            : null
                                    }`}
                                    onClick={() => languageChange("spanish")}
                                >
                                    Español
                                </div>
                                <div
                                    className={`welcome-call-language-btn ml-2 ${
                                        language === "chinese"
                                            ? "welcome-call-language-btn-selected"
                                            : ""
                                    }`}
                                    onClick={() => languageChange("chinese")}
                                >
                                    中文
                                </div>
                            </div>
                        </div>
                        <CalendlyInline
                            changeStep={props.changeStep}
                            repName={rep_name}
                            repEmail={rep_email}
                            brandName={brand_name}
                            locationPhone={location_phone}
                            welcomeCallLinks={props.welcome_call_links}
                        />
                        <script
                            type="text/javascript"
                            src="https://assets.calendly.com/assets/external/widget.js"
                            async
                        ></script>
                    </>
                )}
            </Card>
        );
    } else {
        return null;
    }
}

export default WelcomecallForm;
