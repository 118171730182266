import React from "react";
import { connect } from "react-redux";
import OwnerForm from "../../components/Owner/OwnerForm";
import { postOwner } from "../../store/actions/confirm";
import {
  formatDigitOnly,
  formatPhoneNumber,
  onPhoneKeyDown,
  onPhoneKeyUp,
  onDobKeyUp,
} from "../../utils/digit";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";

class OwnerIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        owner_first_name: "",
        owner_last_name: "",
        owner_has_20_percent: "",
        owner_last_4_ssn: "",
        owner_dob: "",
        owner_phone: "",
        owner_address: "",
        owner_address2: "",
        owner_city: "",
        owner_state: "",
        owner_zipcode: "",
      },
      modalOpen: false,
    };
    this.renderFormData = this.renderFormData.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPhoneKeyUp = this.onPhoneKeyUp.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onOwnerDobKeyUp = this.onOwnerDobKeyUp.bind(this);
  }

  componentDidMount() {
    this.renderFormData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.onboarding !== this.props.onboarding) {
      this.renderFormData();
    }
  }

  renderFormData() {
    let onboarding = this.props.onboarding;
    if (onboarding) {
      if (onboarding.signup_form) {
        let { owner_address } = onboarding.signup_form;
        let address = {};
        if (owner_address) {
          address = {
            owner_address: owner_address.line_1,
            owner_address_2: owner_address.line_2,
            owner_city: owner_address.city,
            owner_state: owner_address.state,
            owner_zipcode: owner_address.zipcode,
          };
        }
        let formData = {
          ...onboarding.signup_form,
          owner_phone: formatPhoneNumber(onboarding.signup_form.owner_phone),
          ...address,
        };
        this.setState({
          formData,
        });
      }
    }
  }

  onFormChange(name, val) {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: val,
      },
    });
  }

  onSubmit(e) {
    e.preventDefault();
    let { src } = this.props.match.params;
    let data = {
      ...this.state.formData,
      src: src,
      owner_phone: formatDigitOnly(this.state.formData.owner_phone),
    };
    this.props.postOwner(data);
  }

  onPhoneKeyUp(e) {
    var ph = onPhoneKeyUp(e, e.target.value);
    let formData = {
      ...this.state.formData,
      owner_phone: ph,
    };
    this.setState({ formData });
  }

  goBack() {
    let { src } = this.props.match.params;
    this.props.history.push({
      pathname: `/onboard/${src}/business`,
    });
  }

  onOwnerDobKeyUp(e) {
    var dob = onDobKeyUp(e, e.target.value);
    let formData = {
      ...this.state.formData,
      owner_dob: dob,
    };
    this.setState({ formData });
  }

  render() {
    return (
      <OwnerForm
        {...this.props}
        data={this.state.formData}
        onFormChange={this.onFormChange}
        onSubmit={this.onSubmit}
        onPhoneKeyUp={this.onPhoneKeyUp}
        onPhoneKeyDown={onPhoneKeyDown}
        confirmOwnerError={this.props.confirmOwnerError}
        confirmOwnerRequest={this.props.confirmOwnerRequest}
        onPrev={this.goBack}
        onOwnerDobKeyUp={this.onOwnerDobKeyUp}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    onboarding: state.auth.onboarding,
    confirmOwnerError: state.confirm.confirmOwnerError,
    confirmOwnerRequest: state.confirm.confirmOwnerRequest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postOwner: (params) => dispatch(postOwner(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerIndex);
