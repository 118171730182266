import React from "react";
import {
    Card,
    FormGroup,
    Button,
    Alert,
    Label,
    Col,
    Row,
    Input,
    InputGroup,
    InputGroupAddon,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import AddressInput from "../Forms/AddressInput";
import PageTitle from "../Common/PageTitle";

function Location(props) {
    let {
        brand_name,
        location_name,
        location_address,
        location_address_2,
        location_email,
        location_phone,
        sales_tax,
    } = props.data;
    const { t } = useTranslation();
    return (
        <Card className="form-card">
            <PageTitle> {t("brand.title")}</PageTitle>
            <form>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label> {t("brand.brand_name")}</Label>
                            <Input
                                type="text"
                                name="brand_name"
                                value={brand_name ?? ""}
                                disabled={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{t("brand.location_nickname")}</Label>
                            <Input
                                type="text"
                                name="location_name"
                                placeholder="e.g. Downtown"
                                defaultValue={"location_name" || ""}
                                onChange={(e) =>
                                    props.onFormChange(
                                        "location_name",
                                        e.target.value
                                    )
                                }
                                value={location_name ?? ""}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col>
                        <FormGroup>
                            <Label>{t("brand.location_address")}</Label>
                            <AddressInput
                                name="location_address"
                                classes="loc_add_query"
                                value={location_address ?? ""}
                                placeholder=""
                                onFormChange={(value) => {
                                    if (value !== location_address) {
                                        props.onFormChange(
                                            "location_address",
                                            value
                                        );
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{t("brand.location_address_2")}</Label>
                            <Input
                                name="location_address_2"
                                value={location_address_2 ?? ""}
                                placeholder="e.g. Ste 100 (optional)"
                                onChange={(e) =>
                                    props.onFormChange(
                                        "location_address_2",
                                        e.target.value
                                    )
                                }
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="">
                            <Label>{t("brand.tax")}</Label>
                            <InputGroup>
                                <Input
                                    required
                                    type="text"
                                    inputMode="decimal"
                                    name="sales_tax"
                                    autoComplete="off"
                                    value={sales_tax ?? ""}
                                    onChange={(e) =>
                                        props.onFormChange(
                                            "sales_tax",
                                            e.target.value
                                        )
                                    }
                                />
                                <InputGroupAddon addonType="prepend">
                                    %
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{t("brand.restaurant_email")}</Label>
                            <Input
                                type="email"
                                name="email"
                                defaultValue={"location_email"}
                                onChange={(e) =>
                                    props.onFormChange(
                                        "location_email",
                                        e.target.value
                                    )
                                }
                                value={location_email ?? ""}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{t("brand.restaurant_phone")}</Label>
                            <Input
                                type="tel"
                                pattern="[0-9]*"
                                name="location_phone"
                                onChange={(e) =>
                                    props.onFormChange(
                                        "location_phone",
                                        e.target.value
                                    )
                                }
                                onKeyUp={props.onPhoneKeyUp}
                                onKeyDown={props.onPhoneKeyDown}
                                value={location_phone ?? ""}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col>
                        {props.confirmLocationError ? (
                            <Alert color="danger" className="mt-3">
                                {props.confirmLocationError}
                            </Alert>
                        ) : null}
                        <Button
                            color="primary"
                            block
                            size="lg"
                            onClick={(e) => props.onSubmit(e)}
                            disabled={props.confirmLocationRequest}
                        >
                            {props.confirmLocationRequest ? (
                                <i className="fad fa-spin fa-spinner mr-2"></i>
                            ) : null}
                            {t("brand.submit")}
                        </Button>
                    </Col>
                </Row>
            </form>
        </Card>
    );
}

export default Location;
