export const spanish = {
    common: {
        questions: "Questions?",
        call_me: "Llameme",
    },
    rep: {
        title_1: "Hola, mi nombre es ",
        title_2:
            ". Lo guiaré a través de este proceso de incorporación. ¿Listo para ir?",
        first_name: "Nombre de pila",
        last_name: "Apellido",
        your_email: "Correo electrónico",
        cell_phone: "Teléfono móvil",
        password: "Crea tu contraseña",
        password_confirmed: "Confirmar Contraseña",
        accept: "Acepto los",
        and: " y la ",
        of_rushable: " de Rushable.",
        terms: "Términos de Servicio",
        policy: " Política de Privacidad",
        submit: "¡HAGAMOSLO",
    },
    verify: {
        title_first_time:
            "Acabo de enviar un código de verificación a su teléfono celular, ingrese el código.",
        title_continue: "Hola Allan, ¿está listo para terminar el proceso?",
        resend: "Reenviar",
        go_back: "REGRESA",
    },
    brand: {
        title: "A continuación, confirme que tenemos la información correcta para su restaurante.",
        brand_name: "Nombre del restaurante",
        location_nickname: "Etiqueta de ubicación",
        location_address: "Dirección Línea 1",
        location_address_2: "Suite, unidad, piso, etc. (👈 importante)",
        restaurant_email: "Correo electrónico del restaurante",
        restaurant_phone: "Teléfono del restaurante",
        tax: "Tasa de impuesto sobre las ventas",
        submit: "SE VE TODO BIEN",
    },
    business: {
        title: "A continuación, tendré que verificar el registro legal de su entidad comercial.",
        business_name: "Nombre de registro legal de la empresa",
        dba: "Nombre comercial  (optativo)",
        business_phone: "Número de teléfono de empresa",
        ein: "EIN comercial",
        address: "Dirección registrada",
        city: "Ciudad",
        state: "Estado",
        zipcode: "Código postal",
        tips: `☝️ Debe ser preciso, incluidos mayúsculas y minúsculas y símbolos. Si el "nombre legal de tu negocio" y el "EIN" no coinciden, tus pagos seran retenidos para una revisión más detallada con el IRS.`,
        tips_letter_case: "",
        tips_and: "",
        tips_symbols: "",
        tips_leftover: "",
        go_back: "VOLVER ATRAS",
        confirm: "CONFIRMAR",
    },
    owner: {
        title: "Disculpe las preguntas personales, pero su información quedará segura. Es necesario que enviemos estos datos al banco para revisión. ",
        owner_first_name: "Nombre legal del dueño de empresa",
        owner_last_name: "Apellido legal del dueño de empresa",
        owner_25: "Dueño de al menos el 25%?",
        owner_phone: "Número de teléfono",
        dob: "Fecha de nacimiento",
        last4: "SSN (los últimos 4 dígitos)",
        state: "Estado",
        zipcode: "Código postal",
        go_back: "VOLVER ATRAS",
        address: "Dirección ",
        confirm: "CONFIRMAR",
        city: "Ciudad",
    },
    plan: {
        title: "Casi ahí! Por favor revisa nuestra suscripción",
        title2: "Ahora vamos a configurar su perfil de pagos",
        monthly_subscription: "Suscripción mensual",
        restaurant_pay: "Paga el Restaurante",
        customer_pay: "Paga el Cliente",
        other_title: "Otro programa de tarifas",
        account_setup: "Tarifa de instalación",
        branded_website: "Página web con su marca",
        on_demand_delivery: "Entregas a domicilio",
        per_month: "por mes",
        per_order: "por orden",
        at_launch: "en el lanzamiento",
        after_launch: "90 días después del lanzamiento",
        per_dispatch: "por envío",
        looks_great: "SE VE MUY BIEN",
        pay_title: "Configurar perfil de pagos",

        go_back: "REGRESA",
        pay: "CONFIRMAR",
        tips: "ℹ️ Usted verá un cargo temporal de $0.50 en la tarjeta para verificación, pero no se preocupe, desaparecerá en unos días.",
    },
    processor: {
        title: "Para procesar pedidos en línea, nos hemos asociado con Stripe, el procesador de tarjetas de crédito más popular y seguro del mundo.",
        stripe: "Stripe",
        stripe_title: "Procesando pago",
        stripe_text:
            "Tarifa fija del 2,90% + 30 ¢ en todas las tarjetas aceptadas. Se requieren tarifas adicionales para tarjetas internacionales. Puedes encontrar más detalles en.",
        companies_title: "Stripe está detrás de estas empresas",
        companies_text:
            "Su procesamiento de pagos está respaldado y asegurado por la misma compañía que también maneja el procesamiento de transacciones para estas compañías.",
        go_back: "REGRESA",
        confirm: "PRÓXIMO PASO",
    },
    terms: {
        title: "También hemos elaborado la Política de privacidad y los Términos de uso para mostrarlos a sus clientes y proteger sus derechos.",
        terms: "Condiciones de Uso",
        policy: "Política de Privacidad",
        preview: "Avance",
        agree_text:
            "He revisado y acepto mostrar esta Política de privacidad y Términos de uso preestablecidos en mi sitio web para los visitantes.",
        go_back: "REGRESA",
        confirm: "PRÓXIMO PASO",
    },
    bank: {
        title: "Para recibir su pago semanal, ingrese la información de su cuenta bancaria para el depósito.",
        account_title: "El título de su cuenta",
        account_type: "Tipo de cuenta bancaria",
        account_name: "Account name",
        account_number: "Número de ruta (Account#)",
        confirm_account_number: "Confirmar número de cuenta (Account#)",
        routing_number: "Routing number",
        stripe: "Stripe",
        stripe_title:
            "Tarifa del procesamiento de pagos para pedidos por internet",
        stripe_text:
            "Tarifa fija de 2.90% + 30¢ por todas las tarjetas aceptadas.Tarifas adicionales se aplican a  las tarjetas internacionales. Vea más detalles en ",
        terms: "Política de privacidad",
        policy: "Términos de uso",
        preview: "Ver",
        agree_text:
            "He revisto y acepto fijar estos términos de uso y política de privacidad en mi sitio web para la vista de los visitantes.",
        confirm: "CONFIRMAR",
    },
    congratulate: {
        title1: "Congratulations on the sign-up 🎉",
        title2: "Please book a ",
        title3: "Welcome Call",
        title4: " with our customer success team to collaborate the onboarding process.",
        next: "¿Que sigue?",
        next1: "Por favor espere 1 a 3 días mientras arreglamos su cuenta.",
        next2: "Nuestro equipo le contactará a Ud. para subir su menú",
        next3: "Le ayudaremos a configurar la tableta para gestionar pedidos.",
    },
    continue: {
        title1: "Hola ",
        title2: ", terminemos lo que dejamos, ¿estás listo?",
        submit: "ESTOY LISTO",
    },
    welcome: {
        title: "¡Verificación completada! Reserve una llamada de bienvenida a continuación con nuestro equipo de soporte para colaborar en el proceso de incorporación.",
        next: "What’s next?",
        next1: "Allow us 1 to 3 days to put your account together.",
        next2: "Our team will also get in touch to upload your menu.",
        next3: "We will help you set up the tablet for managing orders.",
        complete: "COMPLETE AHORA",
        tips: "Notas Especiales:",
    },
    failure: {
        title: "🤔 Vaya, algo salió mal. Si cree que toda la información se envió correctamente, comuníquese conmigo para solucionar el problema.",
        check: "DOBLE VERIFICACIÓN",
        verify: "LLÁMAME",
    },
    questionnaire: {
        title: "Para agilizar su proceso de",
        title2: "incorporación, responda las preguntas a continuación.",
        formData: {
            questionOneTitle: "Conexión de sitio web y dominio",
            questionTwoTitle: "Actualización de listado de Google",
            questionThreeTitle: "Envío de menú",
            questionFourTitle: "Envío de tabletas",
            question1: {
                q: "¿Cómo le gustaría implementar Rushable?",
                a1: "Utilice el sitio web generado por Rushable",
                a2: "Agregar solo la página de pedidos",
            },
            question2: {
                q: "¿Tiene un dominio existente para usar?",
                a1: "Si tengo uno.",
                a2: "No. (Rushable te comprará uno)",
                p: "Ingrese su dominio aquí",
            },
            question3: {
                q: "La implementación requiere conocimientos de DNS y actualizaciones del sitio web, ¿le gustaría que Rushable le ayudara?",
                a1: "Sí, otorgaré el acceso necesario a Rushable.",
                a2: "No, puedo gestionar la implementación por mi cuenta.",
            },
            question4: {
                q: "¿Tiene la propiedad de su listado de Google (perfil de GoogleMyBusiness)?",
                a1: "Sí, tengo la propiedad.",
                a2: "No, no tengo propiedad.",
            },
            question5: {
                q: "Una lista de Google precisa genera más pedidos sin comisiones, ¿le gustaría que Rushable recuperara el acceso en su nombre?",
                a1: "Sí, necesito ayuda de Rushable.",
                a2: "No, sé cómo recuperar el acceso.",
            },
            question6: {
                q: "¿Le gustaría que Rushable ejecute las actualizaciones requeridas en su listado de Google por usted?",
                a1: "Sí, tendré Rushable para realizar las actualizaciones.",
                a2: "No, prefiero hacerlo solo.",
            },
            question7: {
                q: "¿Cómo le gustaría enviar su menú a Rushable?",
                a1: "Subir archivo (s) de menú",
                a2: "Ingrese el enlace web del menú",
            },
            question8: {
                q: "Confirme la dirección de envío de su tableta Rushable:",
                a1: "",
                a2: "Envia a una direccion diferente.",
                p: "Dirección",
            },
            location_address: "Dirección Línea 1",
            uploadTitle: "Subir archivo (s) de menú",
            uploadTip: "Subir archivo",
            linkTitle: "Ingrese el enlace web del menú",
            linkPlaceholder: "Ingrese el enlace web del menú",
            notesTitle:
                "Notas adicionales (modificadores, cambio de precio, etc.)",
            buttonText: "CONFIRMAR",
            backText: "REGRESA",
            dialogText1:
                "¿Por qué te preguntamos si eres el propietario de la ficha de Google de tu restaurante? (Aka: GoogleMyBusiness)",
            dialogText2:
                "La mayoría de su tráfico web proviene de Google, y desea que este tráfico utilice su propio canal de pedidos sin comisiones, en lugar de enrutarlo a plataformas de terceros.",
            dialogText3:
                "Con acceso a su lista de Google, puede mostrar su sitio web y la opción de pedido en línea sin comisiones para que sus clientes elijan. Una actualización exitosa de la lista se verá como el siguiente ejemplo:",
            dialogButton: "Está bien, lo tengo ahora",
        },
    },
    completed_list: {
        title1: "🙌 Impresionante, estás listo.",
        title2: "Nuestro equipo de soporte se hará cargo de aquí. ¡Puede regresar aquí para monitorear el progreso de la incorporación en cualquier momento!",
        q1: "Llamada de bienvenida completada",
        q2: "Preguntas y respuestas del menú completadas",
        q3: "Creación de menú enviada para aprobación",
        q4: "Creación de menú aprobada por usted",
        q5: "Tableta Rushable enviada",
        q6: "Sitio web y dominio configurados",
        q7: "Lista de Google actualizada",
        q8: "Incorporación completada",
        button: "TABLERO ADMINISTRADOR",
    },
};
