import React from "react";

export default function QuestionnaireCard(props) {
    const { title, children } = props;
    return (
        <div className="qn-cart">
            <div className="qn-cart-title">{title}</div>
            <div className="qn-cart-container">{children}</div>
        </div>
    );
}
