import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import {
    Card,
    Form,
    FormGroup,
    Button,
    Label,
    CustomInput,
    Alert,
} from "reactstrap";
import PageTitle from "../Common/PageTitle";
function TermsPage(props) {
    const { t } = useTranslation();

    let [policyAgreement, setPolicyAgreement] = useState(false);
    let [errorMsg, setErrorMsg] = useState(false);

    useEffect(() => {
        if (
            props.onboarding &&
            props.onboarding.signup_form &&
            props.onboarding.signup_form.current_step !== "confirm_policy"
        ) {
            setPolicyAgreement(true);
        }
    }, []);

    return (
        <Card
            className="form-card"
            onSubmit={(e) => {
                e.preventDefault();
                !policyAgreement
                    ? setErrorMsg("The policy agreement is required.")
                    : props.changeStep("confirm_policy");
            }}
        >
            <PageTitle>{t("terms.title")}</PageTitle>
            <Form className="form-p-x">
                <div className="policy-bar">
                    <div className="policy-half-inlineblock borderBottom">
                        <div className="policy-quarter-left">
                            {t("terms.policy")}
                        </div>
                        <div className="policy-quarter-right">
                            <Button
                                color="link"
                                type="button"
                                className="d-inline-block p-0"
                                onClick={props.togglePolicyModal}
                            >
                                {t("terms.preview")}
                            </Button>
                        </div>
                    </div>
                    {/* <div
                        className="policy-quarter-inlineblock"
                        style={{
                            position: "absolute",
                            display: "inline-block",
                            color: "#E1E5E9",
                        }}
                    >
                        |
                    </div> */}
                    <div className="policy-half-inlineblock">
                        <div className="policy-quarter-left">
                            {t("terms.terms")}
                        </div>
                        <div className="policy-quarter-right">
                            <Button
                                color="link"
                                type="button"
                                className="d-inline-block p-0"
                                onClick={props.toggleTermsModal}
                            >
                                {t("terms.preview")}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="terms-check-bar">
                    <FormGroup check className="d-flex bar-p0">
                        <CustomInput
                            type="checkbox"
                            id="agreeCheckbox"
                            name="policyAgreement"
                            checked={policyAgreement || false}
                            onChange={(e) =>
                                setPolicyAgreement(!policyAgreement)
                            }
                        />
                        <Label for="agreeCheckbox" check>
                            {t("terms.agree_text")}
                        </Label>
                    </FormGroup>
                </div>
                {errorMsg ? (
                    <Alert color="danger" className="mt-4">
                        {errorMsg}
                    </Alert>
                ) : null}
                {props.changeStepError ? (
                    <Alert color="danger" className="mt-4">
                        {props.changeStepError}
                    </Alert>
                ) : null}
                <div className="button-row btn-group-next d-flex">
                    <Button
                        block
                        className="mt-0"
                        size="lg"
                        type="button"
                        onClick={(e) => {
                            props.goToStep(1);
                        }}
                    >
                        {t("processor.go_back")}
                    </Button>
                    <Button
                        color="primary"
                        block
                        className="mt-0"
                        size="lg"
                        type="submit"
                        disabled={props.changeStepRequest}
                    >
                        {props.changeStepRequest ? (
                            <i className="fad fa-spin fa-spinner mr-2"></i>
                        ) : null}
                        {t("processor.confirm")}
                    </Button>
                </div>
            </Form>
        </Card>
    );
}

export default TermsPage;
