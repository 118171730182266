import React from "react";
import { Card, FormGroup, Button, Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import CodeInput from "../Common/CodeInput";
import PageTitle from "../Common/PageTitle";
import { formatPhoneNumber } from "../../utils/digit";

function Sendcode(props) {
    const { t } = useTranslation();
    const { signup_form } = props.onboarding;
    if (!signup_form) return null;
    const { crm_representative } = signup_form;
    if (!crm_representative) return null;
    const { phone } = crm_representative;
    var ph_last_4 = phone.substr(6);
    var ph_hide = "***-***-" + phone.substr(6);

    if (props.sendCodeSuccessMsg) {
        var length = props.sendCodeSuccessMsg.length;
        var msg_hide_ph =
            props.sendCodeSuccessMsg.substr(0, length - 10) +
            "***-***-" +
            props.sendCodeSuccessMsg.substr(length - 4, length);
    }
    return (
        <Card className="form-card">
            <div className="">
                <PageTitle>
                    {!props.isReturnLogin ? t("verify.title_first_time") : null}{" "}
                    {props.isReturnLogin ? t("verify.title_continue") : null}
                    {props.isReturnLogin ? ph_last_4 : null}
                    {props.isReturnLogin ? t("verify.title_continue_2") : null}
                </PageTitle>
                {props.sendCodeSuccessMsg ? (
                    <Alert color="success">
                        {props.isReturnLogin
                            ? msg_hide_ph
                            : props.sendCodeSuccessMsg}
                    </Alert>
                ) : null}

                {props.sendCodeError ? (
                    <Alert color="danger">{props.sendCodeError}</Alert>
                ) : null}
                <div className="text-center">
                    <div className="phone-resend">
                        <span className="phone">
                            {" "}
                            {props.isReturnLogin
                                ? ph_hide
                                : formatPhoneNumber(phone)}
                        </span>
                        <Button color="link" onClick={props.resendCode}>
                            {t("verify.resend")}
                        </Button>
                    </div>
                </div>

                <FormGroup>
                    <CodeInput onChange={(value) => props.onChange(value)} />
                </FormGroup>

                {props.loginError ? (
                    <Alert color="danger">{props.loginError}</Alert>
                ) : null}

                {props.loginRequest ? (
                    <div className="my-4 text-center">
                        <i className="fad fa-spin fa-spinner mr-2"></i>
                        Verifing...
                    </div>
                ) : null}

                <Button
                    type="button"
                    color="secondary"
                    block
                    onClick={(e) => props.goBack()}
                >
                    {t("verify.go_back")}
                </Button>
            </div>
        </Card>
    );
}

export default Sendcode;
