import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Form, Button, Alert } from "reactstrap";
import PageTitle from "../Common/PageTitle";
import PoweredByStripeImg from "../../assets/img/powered-by-stripe.png";

function ProcessorPage(props) {
    const { t } = useTranslation();

    useEffect(() => {
        props.clearChangeStepErr();
    }, []);

    return (
        <Card className="form-card">
            <PageTitle>{t("processor.title")}</PageTitle>
            <Form onSubmit={null} className="form-p-x">
                <div className="policy-check-bar mb-32">
                    <div
                        className="f-16 semi-bold text-black"
                        style={{ marginBottom: 16 }}
                    >
                        {t("processor.stripe_title")}
                        <img
                            width={84}
                            style={{
                                marginLeft: 16,
                            }}
                            src={PoweredByStripeImg}
                            alt="PoweredByStripe"
                        />
                    </div>
                    <div
                        style={{
                            fontSize: 16,
                            color: "rgba(9, 34, 53, 0.64)",
                            lineHeight: 1.2,
                        }}
                    >
                        {t("processor.stripe_text")}
                        <a
                            href="https://stripe.com/"
                            target="_brank"
                            style={{
                                fontWeight: 700,
                                color: "#635BFF",
                            }}
                        >
                            {" "}
                            {t("processor.stripe")}
                        </a>
                        .
                    </div>
                    <div className="cc-card-group">
                        <i className="visa"></i>
                        <i className="red-orange-card"></i>
                        <i className="discover"></i>
                        <i className="express"></i>
                        <i className="apple-pay"></i>
                        <i className="google-pay"></i>
                    </div>
                </div>

                <div className="policy-check-bar">
                    <div className="f-16 semi-bold text-black">
                        {t("processor.companies_title")}{" "}
                    </div>
                    <div
                        style={{
                            fontSize: 16,
                            marginTop: 16,
                            marginBottom: 8,
                            lineHeight: 1.2,
                        }}
                    >
                        {t("processor.companies_text")}{" "}
                    </div>
                    <div className="company-logo-group">
                        <span className="company-logo-content">
                            <i className="logo-doordash"></i>
                        </span>
                        <span className="company-logo-content">
                            <i className="logo-ubereats"></i>
                        </span>
                        <span className="company-logo-content">
                            <i className="logo-postmates"></i>
                        </span>
                        <span className="company-logo-content">
                            <i className="logo-chowbus"></i>
                        </span>
                    </div>
                    <div className="company-logo-group">
                        <span className="company-logo-content">
                            <i className="logo-google"></i>
                        </span>
                        <span className="company-logo-content">
                            <i className="logo-lyft"></i>
                        </span>
                        <span className="company-logo-content">
                            <i className="logo-amazon"></i>
                        </span>
                        <span className="company-logo-content">
                            <i className="logo-instacart"></i>
                        </span>
                    </div>
                </div>
                {props.changeStepError ? (
                    <Alert color="danger" className="mt-4">
                        {props.changeStepError}
                    </Alert>
                ) : null}
                <div className="button-row btn-group-next d-flex">
                    <Button
                        block
                        className="mt-0"
                        size="lg"
                        type="button"
                        onClick={props.onPrev}
                    >
                        {t("processor.go_back")}
                    </Button>
                    <Button
                        color="primary"
                        block
                        className="mt-0"
                        size="lg"
                        onClick={(e) =>
                            props.changeStep("confirm_work_with_stripe")
                        }
                        disabled={props.changeStepRequest}
                    >
                        {props.changeStepRequest ? (
                            <i className="fad fa-spin fa-spinner mr-2"></i>
                        ) : null}
                        {t("processor.confirm")}
                    </Button>
                </div>
            </Form>
        </Card>
    );
}

export default ProcessorPage;
