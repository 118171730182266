import React from "react";
import { connect } from "react-redux";

import ContinuePage from "../../components/ContinuePage/ContinuePage";

class ContinueIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return <ContinuePage />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContinueIndex);
