import React from "react";

export default function SelectIcon({ selected }) {
    return (
        <div className="qn-select-icon">
            {selected ? (
                <div className="select-icon-yes">
                    <div className="icon-yes-check"></div>
                </div>
            ) : (
                <div className="select-icon-no"></div>
            )}
        </div>
    );
}
