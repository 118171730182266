import React from "react";
import { Route } from "react-router-dom";
import MainLayout from "./MainLayout";

class MainLayoutRoute extends React.Component {
  render() {
    const { component: Component, location, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => (
          <MainLayout {...props}>
            <Component {...props} />
          </MainLayout>
        )}
      />
    );
  }
}

export default MainLayoutRoute;
