import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import ImgUrl from "../../assets/img/why-dialog-img.png";

export default function WhyDialog({ close }) {
    const { t } = useTranslation();
    return (
        <div className="qn-dialog">
            <div className="qn-dialog-shadow"></div>
            <div className="qn-dialog-container">
                <div className="qn-dialog-close">
                    <i class="far fa-times" onClick={close}></i>
                </div>
                <div className="qn-dialog-text-bold">
                    {t("questionnaire.formData.dialogText1")}
                </div>
                <div className="qn-dialog-text">
                    {t("questionnaire.formData.dialogText2")}
                </div>
                <div className="qn-dialog-text">
                    {t("questionnaire.formData.dialogText3")}
                </div>
                <img className="qn-dialog-img" src={ImgUrl} alt="why-img" />
                <Button
                    type="submit"
                    block
                    color="primary"
                    className="qn-dialog-btn"
                    onClick={close}
                >
                    {t("questionnaire.formData.dialogButton")}
                </Button>
            </div>
        </div>
    );
}
