import React from "react";
import { Card, Button } from "reactstrap";
import { useTranslation } from "react-i18next";

function ContinuePage(props) {
  const { t } = useTranslation();
  return (
    <Card className="form-card">
      <h1>
        {t("continue.title1")}NAME{t("continue.title2")}
      </h1>
      <Button
        color="primary"
        block
        className="mt-0"
        onClick={(e) => (window.location.href = "/send-code")}
      >
        {props.payLocationRequest ? (
          <i className="fad fa-spinner fa-spin mr-2"></i>
        ) : null}
        {t("continue.submit")}
      </Button>
    </Card>
  );
}

export default ContinuePage;
