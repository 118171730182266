import {
    CONFIRM_REP_INFO_REQUEST,
    CONFIRM_REP_INFO_SUCCESS,
    CONFIRM_REP_INFO_FAIL,
    CONFIRM_LOCATION_REQUEST,
    CONFIRM_LOCATION_SUCCESS,
    CONFIRM_LOCATION_FAIL,
    CONFIRM_BUSINESS_REQUEST,
    CONFIRM_BUSINESS_SUCCESS,
    CONFIRM_BUSINESS_FAIL,
    CONFIRM_OWNER_REQUEST,
    CONFIRM_OWNER_SUCCESS,
    CONFIRM_OWNER_FAIL,
    CONFIRM_PAYMENT_REQUEST,
    CONFIRM_PAYMENT_SUCCESS,
    CONFIRM_PAYMENT_FAIL,
    CONFIRM_PAYOUT_REQUEST,
    CONFIRM_PAYOUT_SUCCESS,
    CONFIRM_PAYOUT_FAIL,
    CHANGE_STEP_REQUEST,
    CHANGE_STEP_SUCCESS,
    CHANGE_STEP_FAIL,
    CLEAR_CHANGE_STEP_FAIL,
    QUESTIONS_UPDATE_REQUEST,
    QUESTIONS_UPDATE_SUCCESS,
    QUESTIONS_UPDATE_FAIL,
    CHANGE_LANGUAGE_REQUEST,
    CHANGE_LANGUAGE_SUCCESS,
    CHANGE_LANGUAGE_FAIL,
    QUESTIONS_CONFIRM_REQUEST,
    QUESTIONS_CONFIRM_SUCCESS,
    QUESTIONS_CONFIRM_FAIL,
    ADDR_AUTO_FILL_REQUEST,
    ADDR_AUTO_FILL_SUCCESS,
    ADDR_AUTO_FILL_FAIL,
} from "../actions/confirm";

const initialState = {};

const onboarding = (state = initialState, action) => {
    switch (action.type) {
        case CONFIRM_REP_INFO_REQUEST:
            return {
                ...state,
                confirmRepInfoRequest: true,
                confirmRepInfoError: null,
            };
        case CONFIRM_REP_INFO_SUCCESS:
            return {
                ...state,
                confirmRepInfoRequest: false,
                confirmRepInfoError: null,
            };
        case CONFIRM_REP_INFO_FAIL:
            return {
                ...state,
                confirmRepInfoRequest: false,
                confirmRepInfoError: action.msg,
            };
        case CONFIRM_LOCATION_REQUEST:
            return {
                ...state,
                confirmLocationRequest: true,
            };
        case CONFIRM_LOCATION_SUCCESS:
            return {
                ...state,
                confirmLocationRequest: false,
                confirmLocationError: null,
            };
        case CONFIRM_LOCATION_FAIL:
            return {
                ...state,
                confirmLocationRequest: false,
                confirmLocationError: action.msg,
            };
        case CONFIRM_BUSINESS_REQUEST:
            return {
                ...state,
                confirmBusinessRequest: true,
            };
        case CONFIRM_BUSINESS_SUCCESS:
            return {
                ...state,
                confirmBusinessRequest: false,
                confirmBusinessError: null,
            };
        case CONFIRM_BUSINESS_FAIL:
            return {
                ...state,
                confirmBusinessRequest: false,
                confirmBusinessError: action.msg,
            };
        case CONFIRM_OWNER_REQUEST:
            return {
                ...state,
                confirmOwnerRequest: true,
            };
        case CONFIRM_OWNER_SUCCESS:
            return {
                ...state,
                confirmOwnerRequest: false,
                confirmOwnerError: null,
            };
        case CONFIRM_OWNER_FAIL:
            return {
                ...state,
                confirmOwnerRequest: false,
                confirmOwnerError: action.msg,
            };
        case CONFIRM_PAYMENT_REQUEST:
            return {
                ...state,
                confirmPaymentRequest: true,
            };
        case CONFIRM_PAYMENT_SUCCESS:
            return {
                ...state,
                confirmPaymentRequest: false,
                confirmPaymentError: null,
            };
        case CONFIRM_PAYMENT_FAIL:
            return {
                ...state,
                confirmPaymentRequest: false,
                confirmPaymentError: action.msg,
            };
        case CONFIRM_PAYOUT_REQUEST:
            return {
                ...state,
                confirmPayoutRequest: true,
                confirmPayoutError: null,
                confirmPayoutSuccess: false,
            };
        case CONFIRM_PAYOUT_SUCCESS:
            return {
                ...state,
                confirmPayoutRequest: false,
                confirmPayoutError: null,
                confirmPayoutSuccess: true,
            };
        case CONFIRM_PAYOUT_FAIL:
            return {
                ...state,
                confirmPayoutRequest: false,
                confirmPayoutError: action.msg,
                confirmPayoutSuccess: false,
            };
        case CHANGE_STEP_REQUEST:
            return {
                ...state,
                changeStepRequest: true,
                changeStepError: null,
                changeStepSuccess: false,
            };
        case CHANGE_STEP_SUCCESS:
            return {
                ...state,
                changeStepRequest: false,
                changeStepError: null,
                changeStepSuccess: true,
            };
        case CHANGE_STEP_FAIL:
            return {
                ...state,
                changeStepRequest: false,
                changeStepError: action.msg,
                changeStepSuccess: false,
            };
        case CLEAR_CHANGE_STEP_FAIL:
            return {
                ...state,
                changeStepRequest: false,
                changeStepError: null,
                changeStepSuccess: false,
            };
        case QUESTIONS_UPDATE_REQUEST:
            return {
                ...state,
                questionsUpdateError: null,
            };
        case QUESTIONS_UPDATE_SUCCESS:
            return {
                ...state,
                questionsUpdateError: null,
            };
        case QUESTIONS_UPDATE_FAIL:
            return {
                ...state,
                questionsUpdateError: action.msg,
            };
        case CHANGE_LANGUAGE_REQUEST:
            return {
                ...state,
                changeLanguageError: null,
            };
        case CHANGE_LANGUAGE_SUCCESS:
            return {
                ...state,
                changeLanguageError: null,
            };
        case CHANGE_LANGUAGE_FAIL:
            return {
                ...state,
                changeLanguageError: action.msg,
            };
        case QUESTIONS_CONFIRM_REQUEST:
            return {
                ...state,
                questionsConfirmRequest: true,
                questionsConfirmError: null,
            };
        case QUESTIONS_CONFIRM_SUCCESS:
            return {
                ...state,
                questionsConfirmRequest: false,
                questionsConfirmError: null,
            };
        case QUESTIONS_CONFIRM_FAIL:
            return {
                ...state,
                questionsConfirmRequest: false,
                questionsConfirmError: action.msg,
            };
        case ADDR_AUTO_FILL_REQUEST:
            return {
                ...state,
                autoFillRequest: true,
            };
        case ADDR_AUTO_FILL_SUCCESS:
            return {
                ...state,
                addressSuggestions: action.data,
                autoFillRequest: false,
            };
        case ADDR_AUTO_FILL_FAIL:
            return {
                ...state,
                autoFillRequest: false,
                addressSuggestions: [],
            };
        default:
            return state;
    }
};

export default onboarding;
