import React, { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import {
    Card,
    Form,
    FormGroup,
    Button,
    Label,
    Col,
    Row,
    Input,
    Alert,
} from "reactstrap";
import PageTitle from "../Common/PageTitle";
import PayoutTitleImg from "../../assets/img/payout-title.svg";

function BankInformationForm(props) {
    const { t } = useTranslation();

    let [btnDisabled, setBtnDisabled] = useState(false);
    let [accountHolderName, setAccountHolderName] = useState("");
    let [routingNumber, setRoutingNumber] = useState("");
    let [accountNumber, setAccountNumber] = useState("");
    let [accountHolderType, setAccountHolderType] = useState("");
    let [accountTitle, setAccountTitle] = useState("");
    let [confirmAccountNumber, setConfirmAccountNumber] = useState("");
    let [errorMsg, setErrorMsg] = useState("");

    const stripe = useStripe();

    const handleSubmit = (ev) => {
        ev.preventDefault();

        if (
            !routingNumber ||
            !accountNumber ||
            !confirmAccountNumber ||
            !accountHolderType ||
            !accountHolderName
        ) {
            return setErrorMsg("Please enter all fields.");
        }

        if (accountNumber !== confirmAccountNumber) {
            return setErrorMsg(
                "The account number is inconsistent with the confirmation account number."
            );
        }

        setBtnDisabled(true);

        stripe
            .createToken("bank_account", {
                country: "US",
                currency: "usd",
                routing_number: routingNumber,
                account_number: accountNumber,
                account_holder_name: accountHolderName,
                account_holder_type: accountHolderType,
            })
            .then((result) => {
                setBtnDisabled(false);
                if (result.error) {
                    return setErrorMsg(result.error.message);
                }
                if (result.token) {
                    props.confirmBankAccount(result.token);
                }
            });
    };

    return (
        <Card className="form-card">
            <PageTitle>{t("bank.title")}</PageTitle>
            <div className="form-p-x">
                <div className="payout-title-img">
                    <img src={PayoutTitleImg} alt="payout-title-img" />
                </div>
            </div>
            <Form onSubmit={handleSubmit}>
                <Row form>
                    <Col>
                        <FormGroup>
                            <Label>{t("bank.account_title")}</Label>
                            <Input
                                type="text"
                                name="account_title"
                                value={accountHolderName}
                                onChange={(e) =>
                                    setAccountHolderName(e.target.value)
                                }
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{t("bank.account_type")}</Label>
                            <Input
                                className="my-0"
                                type="select"
                                name="account_holder_type"
                                placeholder="Choose one"
                                required
                                value={accountHolderType}
                                onChange={(e) =>
                                    setAccountHolderType(e.target.value)
                                }
                            >
                                <option value=""></option>
                                <option value="company">
                                    US Business Account
                                </option>
                                <option value="individual">
                                    US Individual Account
                                </option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{t("bank.routing_number")}</Label>
                            <Input
                                type="text"
                                inputMode="numeric"
                                name="rounting_name"
                                required
                                value={routingNumber}
                                onChange={(e) =>
                                    setRoutingNumber(e.target.value)
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{t("bank.account_number")}</Label>
                            <Input
                                type="text"
                                inputMode="numeric"
                                name="account_number"
                                required
                                value={accountNumber}
                                onChange={(e) =>
                                    setAccountNumber(
                                        e.target.value.replace(/\s+/g, "")
                                    )
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{t("bank.confirm_account_number")}</Label>
                            <Input
                                type="text"
                                inputMode="numeric"
                                name="account_number"
                                required
                                value={confirmAccountNumber}
                                onChange={(e) =>
                                    setConfirmAccountNumber(
                                        e.target.value.replace(/\s+/g, "")
                                    )
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {errorMsg ? (
                    <Alert color="danger" className="mt-4">
                        {errorMsg}
                    </Alert>
                ) : null}
                {props.confirmPayoutError ? (
                    <Alert color="danger" className="mt-4">
                        {props.confirmPayoutError}
                    </Alert>
                ) : null}
                <Button
                    color="primary"
                    block
                    size="lg"
                    type="submit"
                    disabled={btnDisabled || props.confirmPayoutRequest}
                >
                    {btnDisabled || props.confirmPayoutRequest ? (
                        <i className="fad fa-spin fa-spinner mr-2"></i>
                    ) : null}
                    {t("bank.confirm")}
                </Button>
            </Form>
        </Card>
    );
}

export default BankInformationForm;
