import React from "react";
import { connect } from "react-redux";
import CompletedListPage from "../../components/CompletedList";

class CompletedList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { onboarding = {} } = this.props.onboarding ?? {};
        return <CompletedListPage onboarding={onboarding} />;
    }
}

const mapStateToProps = (state) => {
    return {
        onboarding: state.auth.onboarding,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CompletedList);
