export const chinese = {
    common: {
        questions: "遇到问题？",
        call_me: "打我电话",
    },
    rep: {
        title_1: "你好，我是",
        title_2:
            "，我将引导你完成开户流程，请确认以下信息是否正确并设置账户密码。",
        first_name: "名字 (First name)",
        last_name: "姓氏 (Last name)",
        your_email: "邮箱 (Email)",
        cell_phone: "手机号码 (Cellphone)",
        password: "创建账户密码",
        password_confirmed: "确认密码",
        accept: "我已阅读并同意 Rushable 的 ",
        and: " 和 ",
        of_rushable: "。",
        terms: "服务条款",
        policy: "隐私政策",
        submit: "继续下一步",
    },
    verify: {
        title_first_time: "我刚刚给你的手机发送了验证码，请输入。",
        title_continue:
            "好极了！首先请让我确认一下你的身份，请在下面输入安全验证码，已经发送到你的尾号为",
        title_continue_2: "的手机，请在下面输入。",
        resend: "重新发送",
        go_back: "返回上一步",
    },
    brand: {
        title: "现在看一下我帮你预填的餐厅信息是否正确。",
        brand_name: "餐厅品牌名",
        location_nickname: "地区名称",
        location_address: "餐厅地址",
        location_address_2: "套房、单元、楼层等（👈重要）",
        restaurant_email: "餐厅邮箱",
        restaurant_phone: "餐厅电话",
        tax: "地区销售税(Sales Tax)",
        submit: "确认无误",
    },
    business: {
        title: "接下来，我需要帮你验证一下你的餐厅的公司注册信息。",
        business_name: "餐厅的公司注册名 (Legal Business Name)",
        dba: "DBA (选填)",
        business_phone: "公司电话",
        ein: "公司税号 (Business EIN)",
        address: "公司注册地址",
        city: "城市",
        state: "州",
        zipcode: "邮编",
        tips: "☝️ 必须跟 IRS 文件上的名字相符，包括",
        tips_letter_case: "大小写",
        tips_and: "和",
        tips_symbols: "标点符号",
        tips_leftover:
            "。如果公司法名与下面填写的 EIN 不匹配，会导致转账冻结直到完成验证。",
        go_back: "返回上一步",
        confirm: "立即验证",
    },
    owner: {
        title: "以下需要一些老板的个人信息，请放心，我们的隐私保护政策会保障你的信息安全，收集这些信息也只是用于核实你的公司合法性。",
        owner_first_name: "你的法定名字",
        owner_last_name: "你的法定姓氏",
        owner_25: "有25%以上的拥有权吗?",
        owner_phone: "联系电话",
        dob: "生日",
        last4: "社会安全号最后4位",
        state: "州",
        zipcode: "邮编",
        go_back: "返回上一步",
        address: "地址",
        confirm: "确认无误",
        city: "城市",
    },
    plan: {
        title: "就快好了！请查看我们的服务订阅费用",
        title2: "请设置一下你的支付信息",
        monthly_subscription: "软件服务订阅",
        restaurant_pay: "餐厅支付",
        customer_pay: "客人支付",
        other_title: "其他相关费用",
        account_setup: "账户开通及设置",
        branded_website: "专属餐厅网站服务",
        on_demand_delivery: "外接司机送餐服务",
        per_month: "每月",
        per_order: "每个订单",
        at_launch: "上线后收取",
        after_launch: "上线90天后收取",
        per_dispatch: "每个派单",
        looks_great: "看着很棒，下一步",
        pay_title: "填写支付信息",

        go_back: "上一步",
        pay: "确认无误",
        tips: "ℹ️ 你会在卡上看到有个 $0.50 的临时收费用于系统验证，不用担心，几天后就会被系统自动清除。",
    },
    processor: {
        title: "我们使用的是 Stripe 的在线支付方案，是全网最受欢迎，最安全可靠的方案提供商。",
        stripe: "Stripe",
        stripe_title: "信用卡处理费",
        stripe_text:
            "每笔成功支付都以 2.9%+30¢ 结算，国际卡会有额外费用。更多细节请查看 ",
        companies_title: "这些公司都在用 Stripe 处理支付",
        go_back: "返回上一步",
        confirm: "前往下一步",
    },
    terms: {
        title: "为了使你的权益受到法律保护，我们为你杜撰了网页上需要显示的隐私政策和使用条款，请过目。",
        terms: "使用条款",
        policy: "隐私政策",
        preview: "预览",
        agree_text:
            "我已查看并同意在我的网页上为访问者显示此预设的隐私政策和使用条款。",
        go_back: "上一步",
        confirm: "继续到下一步",
    },
    bank: {
        title: "请输入你的银行收款信息，我们会以每周转账的方式直接存入你的银行账户。",
        account_title: "账户名称 (Account title)",
        account_type: "账户类型 (Account type)",
        account_name: "Account name",
        account_number: "账户号码 (Account number)",
        confirm_account_number: "确认账户号码 (Account number)",
        routing_number: "银行号码 (Routing number)",
        stripe: "Stripe",
        stripe_title: "在线点餐的信用卡支付费用",
        stripe_text:
            "以下所有支持的信用卡都统一费率: 2.9%+30¢，如果是国际卡则需额外收费，详情请见",
        terms: "使用条款",
        policy: "隐私保护",
        preview: "预览",
        agree_text:
            "我已经审阅并同意在网站或点餐页面上使用这两个预设的隐私保护和使用条款。",
        confirm: "确认无误",
    },
    congratulate: {
        title1: "🙌 太棒了, 祝贺你完成了所有开户步骤，",
        title2: "我们的客服团队将由此接手，能为你服务是我的荣幸，感谢选择 Rushable. ",
        title3: "",
        title4: "",
        next: "下一步是什么？",
        next1: "请允许我们用1到3天完成你的账号设置及检查。",
        next2: "我们团队会尽快与你接洽沟通菜单的上传，以及提供培训。",
        next3: "一台用于接单的平板电脑将在5到7天内寄到你的店里。",
        to_dashboard: "前往餐厅管理后台",
    },
    continue: {
        title1: "Hi ",
        title2: "，让我们继续，你准备好了吗？",
        submit: "我准备好了",
    },
    welcome: {
        title: "验证成功！下面是最后一步，请与我们的客服团队预约一个5分钟的欢迎电话(Welcome Call)，来跟你确认上线过程中的一些细节。",
        next: "下一步是什么？",
        next1: "请允许我们用1到3天完成你的账号设置及检查。",
        next2: "我们团队会尽快与你接洽沟通菜单的上传，以及提供培训。",
        next3: "一台用于接单的平板电脑将在5到7天内寄到你的店里。",
        complete: "确认完成",
        tips: "特殊备注：",
    },
    failure: {
        title: "🤔 貌似出了点问题，如果你认为填写的信息都是正确的，请联系我为你排除故障",
        check: "重新检查一遍",
        verify: "打我电话",
    },
    questionnaire: {
        title: "为了能更顺畅的帮你上线，请协助我",
        title2: "回答以下一些问题",
        formData: {
            questionOneTitle: "网站和域名相关",
            questionTwoTitle: "Google 商家资料相关",
            questionThreeTitle: "菜单资料",
            questionFourTitle: "平板电脑邮寄",
            question1: {
                q: "你希望如何对接与使用 Rushable？",
                a1: "使用 Rushable 为餐厅定制的网站。",
                a2: "只将 Rushable 的点餐部分嵌入我的现有网站。",
            },
            question2: {
                q: "你有没有一个可以使用的域名(domain)？",
                a1: "是的，我有一个。",
                a2: "没有。（Rushable 会免费为你购买一个）",
                p: "在此处输入你的域名",
            },
            question3: {
                q: "对接和上线过程中，需要充足的 DNS 知识和对网站进行更新，你需要 Rushable 代劳吗？",
                a1: "是的，我会提供 Rushable 所需的资料。",
                a2: "不需要，我能自己独立完成所有操作。",
            },
            question4: {
                q: "你是否拥有 Google 商家账户的所有权？（GoogleMyBusiness）",
                a1: "是的，我有所有权。",
                a2: "不，我没有所有权。",
            },
            question5: {
                q: "准确的 Google 列表会带来更多免佣金订单，你希望 Rushable 代表你重新获得访问权限吗？",
                a1: "是的，我需要 Rushable 的帮助。",
                a2: "不，我能够自己拿回所有权。",
            },
            question6: {
                q: "你希望让 Rushable 为你更新必要的 Google 商家资料吗？",
                a1: "是的，我会让 Rushable 来执行更新。",
                a2: "不，我能够自己做。",
            },
            question7: {
                q: "你想如何将你的菜单提交给 Rushable？",
                a1: "上传菜单文件",
                a2: "输入菜单的网页链接",
            },
            question8: {
                q: "请确认接收我们平板电脑的地址。",
                a1: "",
                a2: "运送到不同的地址。",
                p: "输入地址",
            },
            location_address: "确认地址",
            uploadTitle: "上传菜单文件",
            uploadTip: "上传文件",
            linkTitle: "在此输入网页链接",
            linkPlaceholder: "在此输入网页链接",
            notesTitle: "附加说明（可选项、价格变化等）",
            buttonText: "确认无误",
            backText: "返回上一步",
            dialogText1:
                "为什么我们会询问你是否拥有你餐厅的 Google 商家账户的所有权？（又名：GoogleMyBusiness）",
            dialogText2:
                "因为你的绝大部分网络流量都来自 Google，你也一定希望这些流量使用能被引导进自己餐厅的免佣金下单渠道，而不是被引导至其他第 3 方平台抽成平台。",
            dialogText3:
                "如果你拥有 Google 商家账户的所有权，便可以准确地更新客人所能看到的信息以及下单渠道，并将自己的免佣金渠道置顶，如下图：",
            dialogButton: "好的，我知道了。",
        },
    },
    completed_list: {
        title1: "🙌 你真是太棒了，所有签约步骤都已完成。",
        title2: "我们的客服团队将从这里开始接管并开始上线流 程，你也可以随时回到这里查看上线进度！",
        q1: "完成 Wecome Call",
        q2: "完成菜单沟通",
        q3: "发送菜单给你审核",
        q4: "完成菜单审核",
        q5: "寄出 Rushable 平板电脑",
        q6: "完成网站和域名的设置",
        q7: "完成谷歌商家资料更新",
        q8: "完成上线，可开始接单",
        button: "进入 Admin Dashboard",
    },
};
