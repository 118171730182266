import React from "react";
import {
  Card,
  FormGroup,
  Button,
  Alert,
  Label,
  Col,
  Row,
  Input,
} from "reactstrap";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import { BASIC_SELECT_STYLE, BASIC_SELECT_THEME } from "../../utils/constant";
import { filterFromObjectArray } from "../../utils/tools";

function OwnerForm(props) {
  const { t } = useTranslation();

  let {
    owner_first_name,
    owner_last_name,
    owner_has_20_percent,
    owner_last_4_ssn,
    owner_dob,
    owner_phone,
    owner_city,
    owner_state,
    owner_zipcode,
    owner_address,
    owner_address_2,
  } = props.data;

  const switchHas25Per = () => {
    let all_types = [
      { value: 1, label: "Yes" },
      { value: 0, label: "No" },
    ];
    let selected = filterFromObjectArray(
      all_types,
      owner_has_20_percent,
      "value"
    );

    // if (status_locked) message += " (" + status_locked + ")";

    return (
      <>
        <ReactSelect
          value={selected}
          name="owner_has_20_percent"
          options={all_types}
          className="basic-single"
          classNamePrefix="select"
          onChange={(value) =>
            props.onFormChange("owner_has_20_percent", value.value)
          }
          theme={BASIC_SELECT_THEME}
          styles={BASIC_SELECT_STYLE}
        />
      </>
    );
  };

  return (
    <Card className="form-card">
      <h1>{t("owner.title")}</h1>
      <form>
        <Row form>
          <Col>
            <FormGroup>
              <Label>{t("owner.owner_first_name")}</Label>
              <Input
                type="text"
                name="owner_first_name"
                onChange={(e) =>
                  props.onFormChange("owner_first_name", e.target.value)
                }
                value={owner_first_name ?? ""}
                required
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{t("owner.owner_last_name")}</Label>
              <Input
                type="text"
                name="owner_last_name"
                onChange={(e) =>
                  props.onFormChange("owner_last_name", e.target.value)
                }
                value={owner_last_name ?? ""}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label>{t("owner.owner_25")}</Label>
              {switchHas25Per()}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>{t("owner.owner_phone")}</Label>
              <Input
                type="tel"
                pattern="[0-9]*"
                name="owner_phone"
                onKeyUp={props.onPhoneKeyUp}
                onKeyDown={props.onPhoneKeyDown}
                onChange={(e) =>
                  props.onFormChange("owner_phone", e.target.value)
                }
                value={owner_phone ?? ""}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label>{t("owner.dob")}</Label>
              <Input
                className="form-control"
                type="tel"
                pattern="[0-9]*"
                onChange={(e) =>
                  props.onFormChange("owner_dob", e.target.value)
                }
                onKeyUp={props.onOwnerDobKeyUp}
                onKeyDown={props.onPhoneKeyDown}
                value={owner_dob || ""}
                placeholder={"MM-DD-YYYY"}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>{t("owner.last4")}</Label>
              <Input
                type="tel"
                pattern="[0-9]*"
                maxLength={4}
                name="owner_last_4_ssn"
                onChange={(e) =>
                  props.onFormChange("owner_last_4_ssn", e.target.value)
                }
                value={owner_last_4_ssn ?? ""}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label>{t("owner.address")}</Label>
          <Input
            type="text"
            name="owner_address"
            onChange={(e) =>
              props.onFormChange("owner_address", e.target.value)
            }
            value={owner_address ?? ""}
            required
          />
          <Input
            type="text"
            name="owner_address_2"
            placeholder="e.g. Ste 100 (optional)"
            onChange={(e) =>
              props.onFormChange("owner_address_2", e.target.value)
            }
            value={owner_address_2 ?? ""}
            required
          />
        </FormGroup>

        <div className="address-row">
          <div className="address-row-city">
            <FormGroup>
              <Label>{t("owner.city")}</Label>
              <Input
                type="text"
                name="owner_city"
                onChange={(e) =>
                  props.onFormChange("owner_city", e.target.value)
                }
                value={owner_city ?? ""}
                required
              />
            </FormGroup>
          </div>
          <div className="address-row-state">
            <FormGroup>
              <Label>{t("owner.state")}</Label>
              <Input
                type="text"
                name="owner_state"
                onChange={(e) =>
                  props.onFormChange("owner_state", e.target.value)
                }
                value={owner_state ?? ""}
                required
              />
            </FormGroup>
          </div>
          <div className="address-row-zipcode">
            <FormGroup>
              <Label>{t("owner.zipcode")}</Label>
              <Input
                type="text"
                name="owner_zipcode"
                onChange={(e) =>
                  props.onFormChange("owner_zipcode", e.target.value)
                }
                value={owner_zipcode ?? ""}
                required
              />
            </FormGroup>
          </div>
        </div>
      </form>
      {props.confirmOwnerError ? (
        <Alert color="danger" className="mt-3">
          {props.confirmOwnerError}
        </Alert>
      ) : null}
      <div className="button-row btn-group-next">
        <Button
          block
          className="mt-0"
          size="lg"
          onClick={props.onPrev}
          disabled={props.confirmOwnerRequest}
        >
          {t("owner.go_back")}
        </Button>
        <Button
          color="primary"
          block
          size="lg"
          className="mt-0"
          onClick={(e) => props.onSubmit(e)}
          disabled={props.confirmOwnerRequest}
        >
          {props.confirmOwnerRequest ? (
            <i className="fad fa-spin fa-spinner mr-2"></i>
          ) : null}
          {t("owner.confirm")}
        </Button>
      </div>
    </Card>
  );
}

export default OwnerForm;
