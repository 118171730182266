import React, { Fragment } from "react";
import { Input } from "reactstrap";
import SelectIcon from "./SelectIcon";

export default function QuestionnaireSelect(props) {
    const {
        question,
        answers,
        answersType,
        selectNum,
        inputs,
        showWhy,
        openDialog,
        type,
        handleUpdate,
    } = props;
    const update = (index) => {
        !!answersType
            ? handleUpdate(type, answersType[index])
            : handleUpdate(type, index === 0 ? true : false);
    };
    const blur = (type, value) => {
        if (value.length > 0) {
            handleUpdate(type, value);
        }
    };
    return (
        <div className="qn-line">
            <div className="qn-line-question">
                <span>{question}</span>
                {showWhy && (
                    <div className="line-question-why" onClick={openDialog}>
                        <span className="question-why-icon">&#10024;</span>
                        <span className="question-why-text">WHY?</span>
                    </div>
                )}
            </div>
            {answers.map((answer, index) => (
                <Fragment key={answer}>
                    <div
                        className="qn-line-answer"
                        onClick={() => update(index)}
                    >
                        <SelectIcon selected={selectNum === index} />
                        <div className="line-answer-text">{answer}</div>
                    </div>
                    {inputs &&
                        selectNum === index &&
                        inputs.map((input) => {
                            if (input.num === index) {
                                return (
                                    <div className="qn-line-input">
                                        <Input
                                            defaultValue={input.value}
                                            placeholder={input.placeholder}
                                            onBlur={(e) =>
                                                blur(input.type, e.target.value)
                                            }
                                        />
                                    </div>
                                );
                            }
                            return null;
                        })}
                </Fragment>
            ))}
        </div>
    );
}
