import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import MainNavbar from "../../components/Navbar/MainNavbar";
import { withTranslation } from "react-i18next";

import { landing } from "../../store/actions/auth";

class MainLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            openProfile: false,
            openOnboarding: false,
        };
        this.pushToPathname = this.pushToPathname.bind(this);
        this.pushToStep = this.pushToStep.bind(this);
        this.initiateOnboarding = this.initiateOnboarding.bind(this);
    }

    componentDidMount() {
        // GET ONBOARDING INFO
        let { src } = this.props.match.params;
        this.props.landing({ src, refresh: true });
    }

    componentDidUpdate(prevProps) {
        if (this.props.onboarding !== prevProps.onboarding) {
            this.initiateOnboarding();
        }

        // Push to Send Code Route After Send code Succeed
        if (this.props.sendCodeSuccess !== prevProps.sendCodeSuccess) {
            if (this.props.sendCodeSuccess) {
                let { src } = this.props.match.params;
                this.pushToPathname(`/onboard/${src}/send-code`);
            }
        }
    }

    /**
     * Initiate Onboading
     * Route to the right url
     */
    initiateOnboarding() {
        if (this.props.onboarding) {
            let { signup_form, logged_in } = this.props.onboarding;

            //not logged in yet
            if (!logged_in) {
                let { src } = this.props.match.params;
                if (!this.props.sendCodeSuccess) {
                    // need to comfirm representative info
                    this.pushToPathname(`/onboard/${src}/login`);
                    // this.pushToPathname(`/onboard/${src}/payment/3`);
                } else {
                    // need to send code and login
                    this.pushToPathname(`/onboard/${src}/send-code`);
                    // this.pushToPathname(`/onboard/${src}/questionnaire`);
                }
            } else {
                if (signup_form) {
                    this.pushToStep(signup_form.current_step);
                }
            }

            if (signup_form) {
                let { i18n } = this.props;
                i18n.changeLanguage(signup_form.language);
                // i18n.changeLanguage("english");
                // i18n.changeLanguage("chinese");
                // i18n.changeLanguage("spanish");
            }
        }
    }

    /**
     * Push to right route
     * based on signup form step
     * @param {*} current_step
     */
    pushToStep(current_step) {
        let { src } = this.props.match.params;
        let current_path;
        switch (current_step) {
            case 0:
                current_path = "login";
                break;
            case "confirm_location":
                current_path = "location";
                break;
            case "confirm_work_with_stripe":
                current_path = "payment";
                break;
            case "confirm_policy":
                current_path = "payment/2";
                break;
            case "confirm_questions":
                current_path = "questionnaire";
                break;
            case "confirm_payment":
                current_path = "payment/3";
                break;
            case "confirm_payout":
                current_path = "payout";
                break;
            case "confirm_business":
                current_path = "business";
                break;
            case "business_pending_verification":
                current_path = "business";
                break;
            case "business_failed_verification":
                current_path = "fail";
                break;
            case "confirm_welcome_call":
                current_path = "welcome-call";
                break;
            case "completed":
                current_path = "completed_list";
                break;
            default:
                current_path = "completed_list";
                break;
        }

        this.pushToPathname(`/onboard/${src}/${current_path}`);
    }

    /**
     * Push Route
     * @param {*} _pathname
     */
    pushToPathname(_pathname) {
        let { pathname } = this.props.location;
        if (pathname !== _pathname) {
            this.props.history.push({
                pathname: _pathname,
            });
            window.scrollTo(0, 0);
        }
    }

    render() {
        let { children, lang, ...rest } = this.props;

        return (
            <div className="main-layout">
                <Helmet>
                    <title>Rushable Signup</title>
                </Helmet>
                <ToastContainer
                    autoClose={2 * 1000}
                    position={toast.POSITION.TOP_CENTER}
                />
                <MainNavbar {...rest} />
                <div className="main-content">
                    <div className="container">{children}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: "en",
        onboarding: state.auth.onboarding,
        sendCodeSuccess: state.auth.sendCodeSuccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        landing: (params) => dispatch(landing(params)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(MainLayout);
