import axios from "axios";
import Cookies from "js-cookie";
import { setupCache } from "axios-cache-adapter";
import { toast } from "react-toastify";
import { API_URL, TOKEN_KEY } from "../utils/constant";
import { push, replace } from "connected-react-router";

const baseURL = API_URL;
const cache = setupCache({
    maxAge: 5 * 60 * 1000,
    exclude: { query: false },
});

export default {
    setupInterceptors: (store, history) => {
        const TIMEOUT = 15 * 1000;
        axios.defaults.timeout = TIMEOUT;
        axios.defaults.baseURL = baseURL;
        axios.defaults.adapter = cache.adapter;
        axios.defaults.headers = {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json; charset=utf-8",
        };

        axios.interceptors.request.use(
            (config) => {
                if (!config.url.includes("/login")) {
                    config.headers["Authorization"] =
                        "Bearer " + Cookies.get(TOKEN_KEY);
                }
                return config;
            },
            (error) => {
                // 对请求错误做些什么
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => {
                if (response.request.fromCache) {
                    // console.log(response.config.url + " fromCache");
                }
                return response;
            },
            (error) => {
                const status =
                    error.status ||
                    (error.response ? error.response.status : 0);

                let msg = error.message;
                switch (status) {
                    case 400:
                        console.error("400 Request Error");
                        break;

                    case 401:
                        error.message = "Please log in";
                        Cookies.remove(TOKEN_KEY);
                        break;

                    case 403:
                        console.error("Access Denied");
                        msg = "Access denied.";
                        break;

                    case 404:
                        console.error(
                            `Request address error: ${error.response.config.url}`
                        );
                        break;

                    case 408:
                        console.error("408 Request Timeout");
                        break;

                    case 429:
                        console.error("429 Too Many Requests");
                        break;

                    case 500:
                        console.error("500 Internal Error");
                        break;

                    case 501:
                        console.error("Service Unimplemented");
                        break;

                    case 502:
                        msg = "Server not responding";
                        console.error("Network Error");
                        break;

                    case 503:
                        console.error("Inavailable Service");
                        break;

                    case 504:
                        msg = "Unstable network";
                        console.error("Network Timeout");
                        break;

                    case 505:
                        console.error("Unsupported HTTP Version");
                        break;

                    default:
                        console.error("Unknown Error");
                        break;
                }

                if (!window.navigator.onLine) {
                    console.info("Offline");
                    toast.error("You are offline. Please check your network.", {
                        autoClose: 30000,
                    });
                } else {
                    if (error.code === "ECONNABORTED") {
                        msg = "Request timeout. Please try again.";
                    }
                }
                const errData = error.response?.data || {
                    message: error.message,
                    errors: {},
                };
                return Promise.reject(errData);
            }
        );
    },
};
