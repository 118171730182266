// CheckoutForm.js
import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Form, Alert, Button } from "reactstrap";
import { useTranslation } from "react-i18next";

const CheckoutForm = (props) => {
    const { onboarding = {} } = props;
    const { signup_form = {} } = onboarding;
    const { language } = signup_form;
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    let [errorMsg, setErrorMsg] = useState("");
    let [processing, setProcessing] = useState(false);

    let { signup_fee, delay } = props.data;
    let origin_signup_fee = 199;
    let due_today = 0;
    if (delay === 0) {
        due_today = signup_fee;
    }

    const createOptions = (fontSize, padding) => {
        return {
            style: {
                base: {
                    fontSize,
                    color: "#092235",
                    letterSpacing: "0.025em",
                    fontFamily: '"Open Sans", sans-serif',
                    "::placeholder": {
                        color: "#828b92",
                    },
                    padding,
                },
                invalid: {
                    color: "#f24242",
                },
            },
        };
    };

    const handleSubmit = (ev) => {
        ev.preventDefault();

        if (!stripe || !elements) return;

        if (processing || props.confirmPaymentRequest) return;

        const cardElement = elements.getElement("card");

        setErrorMsg("");
        setProcessing(true);

        stripe
            .createPaymentMethod({
                type: "card",
                card: cardElement,
            })
            .then((result) => {
                if (result.error) {
                    setErrorMsg(result.error.message);
                } else {
                    let payment_method_id = result.paymentMethod.id;
                    //confirm function
                    props.confirmPayment(payment_method_id, props?.amount);
                }
                setProcessing(false);
            });
    };

    return (
        <Form className="stripe_form form-p-x" onSubmit={handleSubmit}>
            <div className="info-card gray plan-summary">
                <div className="plan-title">{t("plan.pay_title")}</div>
                <div className="mt-2">
                    <div className="form-control">
                        <CardElement {...createOptions("16px")} />
                    </div>
                    <div
                        className={`mt-3 plan-tips ${
                            language === "spanish" ? "plan-tips-spanish" : ""
                        }`}
                    >
                        {t("plan.tips")}
                    </div>
                    {errorMsg ? (
                        <Alert color="danger" className="mt-3">
                            {errorMsg}
                        </Alert>
                    ) : null}
                    {props.confirmPaymentError ? (
                        <Alert color="danger" className="mt-3">
                            {props.confirmPaymentError}
                        </Alert>
                    ) : null}
                </div>
            </div>
            <div className="button-row btn-group-next d-flex">
                <Button
                    block
                    className="mt-0"
                    size="lg"
                    type="button"
                    onClick={props.goBack}
                    disabled={props.confirmPaymentRequest}
                >
                    {t("plan.go_back")}
                </Button>
                <Button
                    color="primary"
                    block
                    className="mt-0"
                    size="lg"
                    disabled={processing || props.confirmPaymentRequest}
                    type="submit"
                >
                    {processing || props.confirmPaymentRequest ? (
                        <i className="fad fa-spin fa-spinner mr-2"></i>
                    ) : null}
                    {t("plan.pay")}
                </Button>
            </div>
        </Form>
    );
};

export default CheckoutForm;
