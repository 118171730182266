import React from "react";
import ReactSlider from "react-slider";
import cn from "classnames";

export default function DragBar({
    className,
    value,
    discount,
    min = 0,
    max = 100,
    step = 1,
    disabled,
    onChange,
}) {
    return (
        <div className={cn("ru-dragbar", className)}>
            {discount > 0 && (
                <div
                    className="ru-dargbar-extra"
                    style={{
                        width: `${(discount / (max - min + discount)) * 100}%`,
                    }}
                />
            )}
            <ReactSlider
                min={min}
                max={max}
                step={step}
                value={value}
                className={cn(
                    "dragbar-slider",
                    discount > 0 ? "more-than-0" : ""
                )}
                trackClassName={cn("dragbar-track")}
                thumbClassName="dragbar-thumb"
                renderThumb={(props, state) => (
                    <div {...props}>
                        <div />
                        <div />
                    </div>
                )}
                onChange={onChange}
            />
        </div>
    );
}
