export const DOMAIN = process.env.REACT_APP_API_DOMAIN ?? "https://test.com";

export const API_URL = DOMAIN + "client";

export const RUSHABLE_DASHBOARD = "https://admin.rushable.io";

// this is chloe's gmail acct
export const GOOGLE_MAP_API_CODE = process.env.REACT_APP_GOOGLE_MAP_API_CODE;

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const MAIN_API_URL = process.env.REACT_APP_MAIN_API;

export const TOKEN_KEY = "access_token";

export const BASIC_SELECT_THEME = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        primary25: "#EAEAEA",
        primary: "#03C9A9",
    },
});

export const BASIC_SELECT_STYLE = {
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#ccc",
        };
    },
    input: (base) => ({
        ...base,
        padding: 0,
        margin: 0,
    }),
};

export const DAYS = [
    {
        label: "Sunday",
        abbr: "sun",
    },
    {
        label: "Monday",
        abbr: "mon",
    },
    {
        label: "Tuesday",
        abbr: "tue",
    },
    {
        label: "Wednesday",
        abbr: "wed",
    },
    {
        label: "Thursday",
        abbr: "thu",
    },
    {
        label: "Friday",
        abbr: "fri",
    },
    {
        label: "Saturday",
        abbr: "sat",
    },
];
