import {
    AUTH_LOGIN_REQUEST,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_FAIL,
    AUTH_LANDING_REQUEST,
    AUTH_LANDING_SUCCESS,
    AUTH_LANDING_FAIL,
    AUTH_SENDCODE_REQUEST,
    AUTH_SENDCODE_SUCCESS,
    AUTH_SENDCODE_FAIL,
    AUTH_LOGOUT,
} from "../actions/auth";

const initialState = {
    loginRequest: false,
    loginError: "",
    logoutRequest: false,
    onboarding: {},
    sendCodeSuccess: false,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_LOGIN_REQUEST:
            return {
                ...state,
                loginRequest: true,
                loginError: null,
            };
        case AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                loginRequest: false,
                loginError: null,
            };
        case AUTH_LOGIN_FAIL:
            return {
                ...state,
                loginRequest: false,
                loginError: action.msg,
            };

        case AUTH_LANDING_REQUEST:
            return {
                ...state,
                landingRequest: true,
            };
        case AUTH_LANDING_SUCCESS:
            return {
                ...state,
                onboarding: action.data,
                landingRequest: false,
                landingError: null,
            };
        case AUTH_LANDING_FAIL:
            return {
                ...state,
                landingRequest: false,
                landingError: action.msg,
            };
        case AUTH_SENDCODE_REQUEST:
            return {
                ...state,
                sendCodeRequest: true,
                sendCodeSuccess: false,
                sendCodeSuccessMsg: null,
                sendCodeError: null,
            };
        case AUTH_SENDCODE_SUCCESS:
            return {
                ...state,
                sendCodeRequest: false,
                sendCodeError: null,
                sendCodeSuccess: true,
                sendCodeSuccessMsg: action.msg,
            };
        case AUTH_SENDCODE_FAIL:
            return {
                ...state,
                sendCodeRequest: false,
                sendCodeError: action.msg,
                sendCodeSuccess: false,
                sendCodeSuccessMsg: null,
            };
        case AUTH_LOGOUT:
            return {};

        default:
            return state;
    }
};

export default auth;
