import React from "react";
import { Card, Button, Alert, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/Common/PageTitle";

function ReturnLogin(props) {
    const { t } = useTranslation();
    if (!props.onboarding.signup_form) return null;
    const { first_name } = props.onboarding.signup_form.crm_representative;
    return (
        <Card className="form-card">
            <PageTitle styles={{ marginBottom: 48 }}>
                {t("continue.title1")}
                {`${first_name}`}
                {t("continue.title2")}
            </PageTitle>
            <Row form>
                <Col>
                    {props.sendCodeError ? (
                        <Alert color="danger">{props.sendCodeError}</Alert>
                    ) : null}
                    <Button
                        color="primary"
                        block
                        className="mt-0"
                        onClick={(e) => props.onSubmit(e)}
                        disabled={props.sendCodeRequest}
                    >
                        {props.sendCodeRequest ? (
                            <i className="fad fa-spinner fa-spin mr-2"></i>
                        ) : null}
                        {t("continue.submit")}
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}

export default ReturnLogin;
