import React from "react";
import { Button } from "reactstrap";
import blackLogo from "../../assets/img/logo-black.png";
import { useTranslation } from "react-i18next";
import { DOMAIN } from "../../utils/constant";

function MainNavbar(props) {
    const { t } = useTranslation();
    let closer_photo = "";
    let source = "";
    let closer_phone = null;
    if (props.onboarding) {
        let { closer, signup_form } = props.onboarding;
        if (closer) {
            closer_photo = closer.profile_photo
                ? `${DOMAIN}${closer.profile_photo}`
                : null;
            closer_phone = closer.phone;
        }
        if (signup_form) {
            source = signup_form.source;
        }
    }

    return (
        <nav className="navbar main-nav">
            <div className="container">
                <div className="navbar-brand">
                    <img
                        alt="Rushable Logo"
                        src={blackLogo}
                        className="nav-logo"
                    />
                </div>

                {closer_photo ? (
                    <span
                        className="head-img"
                        style={{ backgroundImage: `url(${closer_photo})` }}
                    ></span>
                ) : null}
                {closer_phone && source !== "affiliate" ? (
                    <div className="d-inline-flex question-contact">
                        <span className="m-auto semi-bold">
                            {t("common.questions")}
                        </span>
                        <Button
                            className="call-me"
                            href={`tel:${closer_phone}`}
                        >
                            {t("common.call_me")}
                        </Button>
                    </div>
                ) : null}
            </div>
        </nav>
    );
}

export default MainNavbar;
