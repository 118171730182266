import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import i18n from "../../i18n";
import QuestionnaireForm from "../../components/Questionnaire/QuestionnaireForm";
import QuestionnaireTitle from "../../components/Questionnaire/QuestionnaireTitle";
import QuestionnaireSubmit from "../../components/Questionnaire/QuestionnaireSubmit";
import WhyDialog from "../../components/Questionnaire/WhyDialog";
import {
    questionsUpdate,
    changeLanguage,
    questionsConfirm,
    uploadFile,
    deleteFile,
} from "../../store/actions/confirm";

class Questionnaire extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dislogShow: false,
            formData: {
                website_preference: null,
                has_existing_domain: null,
                self_domain: null,
                delegate_dns_or_website: null,
                has_gmb_ownership: null,
                need_help_regain_gmb: null,
                delegate_gmb_update: null,
                menu_source: null,
                menu_work_notes: null,
                menu_link: null,
                files: [],
                shipping_destination: null,
                tablet_shipping_address: null,
                shipping_address_query: "",
                shipping_address_line_2: "",
            },
            language: "english",
            isComplete: false,
            updating: false,
            address2Editing: false,
        };
    }

    componentDidMount() {
        if (this.props.onboarding) {
            const { onboarding, signup_form } = this.props.onboarding;
            if (signup_form && signup_form.language) {
                this.setState({
                    language: signup_form.language,
                });
            }
            if (onboarding) {
                this.setState({
                    formData: {
                        website_preference: onboarding.website_preference,
                        has_existing_domain: onboarding.has_existing_domain,
                        self_domain: onboarding.self_domain,
                        delegate_dns_or_website:
                            onboarding.delegate_dns_or_website,
                        has_gmb_ownership: onboarding.has_gmb_ownership,
                        need_help_regain_gmb: onboarding.need_help_regain_gmb,
                        delegate_gmb_update: onboarding.delegate_gmb_update,
                        menu_source: onboarding.menu_source,
                        menu_work_notes: onboarding.menu_work_notes,
                        menu_link: onboarding.menu_link,
                        files: onboarding.onboarding_menus,
                        shipping_destination: onboarding.shipping_destination,
                        tablet_shipping_address:
                            onboarding.tablet_shipping_address,
                        shipping_address_query:
                            onboarding.tablet_shipping_address
                                ? `${
                                      onboarding.tablet_shipping_address.line_1
                                          ? onboarding.tablet_shipping_address
                                                .line_1 + " "
                                          : ""
                                  }${
                                      onboarding.tablet_shipping_address.city
                                          ? onboarding.tablet_shipping_address
                                                .city + " "
                                          : ""
                                  }${
                                      onboarding.tablet_shipping_address.line_2
                                          ? onboarding.tablet_shipping_address
                                                .line_2 + " "
                                          : ""
                                  }${
                                      onboarding.tablet_shipping_address.state
                                          ? onboarding.tablet_shipping_address
                                                .state + " "
                                          : ""
                                  }${
                                      onboarding.tablet_shipping_address.zipcode
                                          ? onboarding.tablet_shipping_address
                                                .zipcode + " "
                                          : ""
                                  }`
                                : "",
                        shipping_address_line_2:
                            onboarding.tablet_shipping_address &&
                            onboarding.tablet_shipping_address.line_2
                                ? onboarding.tablet_shipping_address.line_2
                                : "",
                    },
                    isComplete: onboarding.is_completed_questions,
                });
            }
        }
    }

    dialogClose = () => {
        this.setState({
            dislogShow: false,
        });
    };

    dialogOpen = () => {
        this.setState({
            dislogShow: true,
        });
    };

    handleUpdate = async (type, action) => {
        if (type === "has_existing_domain" && !action) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    self_domain: "",
                    [type]:
                        typeof action === "boolean" ? (action ? 1 : 0) : action,
                },
            });
        } else if (
            type === "website_preference" &&
            action === "ordering_page_only"
        ) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    has_existing_domain: null,
                    self_domain: "",
                    [type]:
                        typeof action === "boolean" ? (action ? 1 : 0) : action,
                },
            });
        } else if (type === "website_preference" && action === "full_website") {
            this.setState({
                formData: {
                    ...this.state.formData,
                    delegate_dns_or_website: null,
                    [type]:
                        typeof action === "boolean" ? (action ? 1 : 0) : action,
                },
            });
        } else if (type === "has_gmb_ownership" && action) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    need_help_regain_gmb: null,
                    [type]:
                        typeof action === "boolean" ? (action ? 1 : 0) : action,
                },
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [type]:
                        typeof action === "boolean" ? (action ? 1 : 0) : action,
                },
            });
        }

        if (type === "has_existing_domain" && action) return;

        if (type === "shipping_destination" && action === "other") return;
        const { src } = this.props.match.params;
        let params = {};
        params = {
            src,
            [type]: action,
        };
        if (type === "self_domain") {
            params["has_existing_domain"] =
                this.state.formData["has_existing_domain"];
        }

        try {
            this.setState({
                updating: true,
            });
            const res = await this.props.questionsUpdate(params);
            this.setState({
                isComplete: res["Completed_Q&A"],
                updating: false,
            });
        } catch (error) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [type]: null,
                    updating: false,
                },
            });
        }
    };

    formDataChange = (type, action) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: typeof action === "boolean" ? (action ? 1 : 0) : action,
            },
        });
    };

    languageChange = (type) => {
        if (type !== this.state.language) {
            const { src } = this.props.match.params;
            const params = {
                src,
                language: type,
            };
            this.props.changeLanguage(params);
            i18n.changeLanguage(type);
            this.setState({
                language: type,
            });
        }
    };

    uploadFile = async (e) => {
        if (e.target.files.length === 0) {
            return;
        }

        const { src } = this.props.match.params;
        let file = e.target.files[0];
        const { name } = file;
        this.setState({
            formData: {
                ...this.state.formData,
                files: this.state.formData.files.concat([
                    {
                        id: "loading",
                        file_name: name,
                    },
                ]),
            },
        });
        const params = {
            src,
            files: [file],
            type: "menu",
        };
        try {
            const res = await this.props.uploadFile(params);
            if (res) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        files: res.onboarding_menus,
                    },
                });
            } else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        files: this.state.formData.files.filter(
                            (v) => v.id !== "loading"
                        ),
                    },
                });
            }
        } catch (err) {
            toast.warn("Please try again.");
        }
    };

    deleteFile = async (id) => {
        this.setState({
            formData: {
                ...this.state.formData,
                files: this.state.formData.files.map((file) => {
                    if (file.id === id) file.deleting = true;
                    return file;
                }),
            },
        });
        const params = {
            google_drive_file_id: id,
            type: "menu",
        };
        try {
            const res = await this.props.deleteFile(params);
            if (res) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        files: this.state.formData.files.filter(
                            (file) => id !== file.id
                        ),
                    },
                });
            } else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        files: this.state.formData.files.map((file) => {
                            if (file.id === id) file.deleting = false;
                            return file;
                        }),
                    },
                });
            }
        } catch (err) {
            toast.warn("Please try again.");
        }
    };

    submit = () => {
        const { src } = this.props.match.params;
        const params = {
            src,
        };
        this.props.questionsConfirm(params);
    };

    goBack = () => {
        const { src } = this.props.match.params;
        this.props.history.push({
            pathname: `/onboard/${src}/payment/2`,
        });
    };

    handleAddress2Editing = (type) => {
        this.setState({
            address2Editing: type,
        });
    };

    render() {
        return (
            <div className="questionnaire">
                <QuestionnaireTitle />
                <div className="qn-language">
                    <div
                        className={`qn-language-btn mr-1 ${
                            this.state.language === "english"
                                ? "qn-language-btn-selected"
                                : ""
                        }`}
                        onClick={() => this.languageChange("english")}
                    >
                        English
                    </div>
                    <div
                        className={`qn-language-btn ${
                            this.state.language === "spanish"
                                ? "qn-language-btn-selected"
                                : null
                        }`}
                        onClick={() => this.languageChange("spanish")}
                    >
                        Español
                    </div>
                    <div
                        className={`qn-language-btn ml-1 ${
                            this.state.language === "chinese"
                                ? "qn-language-btn-selected"
                                : ""
                        }`}
                        onClick={() => this.languageChange("chinese")}
                    >
                        中文
                    </div>
                </div>
                <QuestionnaireForm
                    formData={this.state.formData}
                    openDialog={this.dialogOpen}
                    handleUpdate={this.handleUpdate}
                    formDataChange={this.formDataChange}
                    uploadFile={this.uploadFile}
                    deleteFile={this.deleteFile}
                    address={
                        this.props.onboarding &&
                        this.props.onboarding.signup_form &&
                        this.props.onboarding.signup_form.location_address
                    }
                    handleAddress2Editing={this.handleAddress2Editing}
                />
                <QuestionnaireSubmit
                    questionsConfirmRequest={this.props.questionsConfirmRequest}
                    formData={this.state.formData}
                    isComplete={this.state.isComplete}
                    updating={this.state.updating}
                    address2Editing={this.state.address2Editing}
                    submit={this.submit}
                    goBack={this.goBack}
                />
                {this.state.dislogShow && (
                    <WhyDialog close={this.dialogClose} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        onboarding: state.auth.onboarding,
        questionsConfirmRequest: state.confirm.questionsConfirmRequest,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        questionsUpdate: (params) => dispatch(questionsUpdate(params)),
        changeLanguage: (params) => dispatch(changeLanguage(params)),
        questionsConfirm: (params) => dispatch(questionsConfirm(params)),
        uploadFile: (params) => dispatch(uploadFile(params)),
        deleteFile: (params) => dispatch(deleteFile(params)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
