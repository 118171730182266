import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { landing } from "../store/actions/auth";
import Login from "./login/index";
import Sendcode from "./sendcode/index";
import LocationIndex from "./location";
import BusinessIndex from "./business/index";
import OwnerIndex from "./owner/index";
import Questionnaire from "./questionnaire";
import PaymentIndex from "./payment/index";
import PayoutIndex from "./payout/index";
import WelcomecallIndex from "./welcomecall/index";
import ContinueIndex from "./continue/index";
import SuccessIndex from "./success/index";
import FailureIndex from "./failure/index";
import VerificationIndex from "./verification/index";
import CompletedList from "./completedList/index";
import { Alert } from "reactstrap";

class Landing extends React.Component {
    componentDidMount() {}
    render() {
        let { landingRequest, landingError } = this.props;
        if (landingRequest) {
            return (
                <div className="container">
                    <div className="my-5 text-center">
                        <i className="fad fa-spin fa-spinner"></i>
                    </div>
                </div>
            );
        }

        if (landingError) {
            return (
                <Alert color="danger" className="mt-5">
                    {landingError}
                </Alert>
            );
        }
        return (
            <Switch>
                <Route
                    path="/onboard/:src/login"
                    render={(props) => <Login {...props} />}
                />
                <Route
                    path="/onboard/:src/continue-send-code"
                    render={(props) => <Sendcode {...props} />}
                />
                <Route
                    path="/onboard/:src/send-code"
                    render={(props) => <Sendcode {...props} />}
                />
                <Route
                    path="/onboard/:src/location"
                    render={(props) => <LocationIndex {...props} />}
                />
                <Route
                    path="/onboard/:src/business"
                    render={(props) => <BusinessIndex {...props} />}
                />
                <Route
                    path="/onboard/:src/owner"
                    render={(props) => <OwnerIndex {...props} />}
                />
                <Route
                    path="/onboard/:src/questionnaire"
                    render={(props) => <Questionnaire {...props} />}
                />
                <Route
                    path="/onboard/:src/payment"
                    render={(props) => <PaymentIndex {...props} />}
                />
                <Route
                    path="/onboard/:src/payout"
                    render={(props) => <PayoutIndex {...props} />}
                />
                <Route
                    path="/onboard/:src/continue"
                    render={(props) => <ContinueIndex {...props} />}
                />
                <Route
                    path="/onboard/:src/welcome-call"
                    render={(props) => <WelcomecallIndex {...props} />}
                />
                <Route
                    path="/onboard/:src/verify"
                    render={(props) => <VerificationIndex {...props} />}
                />
                <Route
                    path="/onboard/:src/success"
                    render={(props) => <SuccessIndex {...props} />}
                />
                <Route
                    path="/onboard/:src/fail"
                    render={(props) => <FailureIndex {...props} />}
                />
                <Route
                    path="/onboard/:src/completed_list"
                    render={(props) => <CompletedList {...props} />}
                />
            </Switch>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        onboarding: state.auth.onboarding,
        landingRequest: state.auth.landingRequest,
        landingError: state.auth.landingError,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        landing: (params) => dispatch(landing(params)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Landing);
