import React from "react";
import { connect } from "react-redux";
import SuccessForm from "../../components/Success/SuccessForm";

class SuccessIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return <SuccessForm />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessIndex);
