import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Close from "../../components/Common/Close";
import logo from "../../assets/img/logo-black.png";

class AuthLayout extends React.Component {
    componentDidMount() {}

    componentWillUnmount() {}

    componentDidUpdate(prevProps) {}

    render() {
        return (
            <div className="auth-layout">
                <ToastContainer
                    autoClose={2 * 1000}
                    position={toast.POSITION.TOP_CENTER}
                    closeButton={<Close />}
                />
                <div className="auth-main">
                    <div className="container" style={{ paddingTop: "60px" }}>
                        <a href="javascript:;">
                            <img
                                src={logo}
                                alt="Rushable logo"
                                className="rushable-logo"
                            />
                        </a>
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
