import React from "react";
import { Card, Button } from "reactstrap";

import { useTranslation } from "react-i18next";
import { RUSHABLE_DASHBOARD } from "../../utils/constant";

function SuccessForm(props) {
  const { t, i18n } = useTranslation();

  return (
    <Card className="form-card welcome-call">
      <h1>
        {t("congratulate.title1")}
        <br />
        {t("congratulate.title2")}
      </h1>

      <Button
        color="primary"
        block
        size="lg"
        onClick={(e) => window.open(RUSHABLE_DASHBOARD)}
        disabled={props.confirmLocationRequest}
      >
        {props.confirmLocationRequest ? (
          <i className="fad fa-spin fa-spinner mr-2"></i>
        ) : null}
        {t("congratulate.to_dashboard")}
      </Button>
    </Card>
  );
}

export default SuccessForm;
