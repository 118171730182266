import React from "react";
import currency from "currency.js";
import { useTranslation } from "react-i18next";

export default function PaymentDetail({ product }) {
    const { t } = useTranslation();
    return (
        <div className="info-card gray payment-detail">
            <div className="payment-title">{t("plan.other_title")}</div>
            <div className="payment-detail-item">
                <div className="pdi-left">{t("plan.account_setup")}</div>
                <div className="pdi-right">
                    {currency(product?.signup_fee_discount).value > 0 ? (
                        <div className="pdi-price">
                            <span className="pdi-price-blue">
                                {currency(product?.signup_fee).format()}
                            </span>
                            {currency(product?.signup_fee)
                                .subtract(product?.signup_fee_discount)
                                .format()}
                        </div>
                    ) : (
                        <div className="pdi-price">
                            {currency(product?.signup_fee).format()}
                        </div>
                    )}

                    <div className="pdi-type">
                        {currency(product?.signup_fee).value === 199
                            ? t("plan.at_launch")
                            : t("plan.after_launch")}
                    </div>
                </div>
            </div>
            <div className="payment-detail-item">
                <div className="pdi-left">{t("plan.branded_website")}</div>
                <div className="pdi-right">
                    <div className="pdi-price">
                        {product?.mention_free_website ? (
                            <>
                                <span className="pdi-price-blue">$99.00</span>
                                FREE
                            </>
                        ) : (
                            "$99.00"
                        )}
                    </div>
                    <div className="pdi-type">{t("plan.per_month")}</div>
                </div>
            </div>
            <div className="payment-detail-item">
                <div className="pdi-left">{t("plan.on_demand_delivery")}</div>
                <div className="pdi-right">
                    <div className="pdi-price">
                        {/* <span className="pdi-price-blue">$1.50</span>
                        $0.50 */}
                        {currency(product?.dispatch_fee).format()}
                    </div>
                    <div className="pdi-type">{t("plan.per_dispatch")}</div>
                </div>
            </div>
        </div>
    );
}
