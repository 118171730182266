import React from "react";
import { connect } from "react-redux";
import PaymentForm from "../../components/Payment/PaymentForm";
import ProcessorPage from "../../components/Payment/ProcessorPage";
import TermsPage from "../../components/Payment/TermsPage";
import {
    postPayment,
    changeStep,
    clearChangeStepErr,
} from "../../store/actions/confirm";
import PrivacyPolicy from "../../components/Modals/PrivacyPolicy";
import TermsOfUse from "../../components/Modals/TermsOfUse";
import { Route, Switch } from "react-router-dom";

class PaymentIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            step: 1,
            policyModalOpen: false,
            termsModalOpen: false,
            rushablePlan: [],
        };
        this.renderFormData = this.renderFormData.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.updatePaymentMethod = this.updatePaymentMethod.bind(this);
        this.goBack = this.goBack.bind(this);
        this.confirmPayment = this.confirmPayment.bind(this);
        this.goToStep = this.goToStep.bind(this);
        this.togglePolicyModal = this.togglePolicyModal.bind(this);
        this.toggleTermsModal = this.toggleTermsModal.bind(this);
        this.changeStep = this.changeStep.bind(this);
    }

    componentDidMount() {
        this.renderFormData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.onboarding !== this.props.onboarding) {
            this.renderFormData();
        }
    }

    changeStep(step) {
        let { src } = this.props.match.params;
        this.props.changeStep({ step: step, src: src, agree: true });
    }

    onFormChange(name, val) {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val,
            },
        });
    }

    // onSubmit(e) {
    //   e.preventDefault();
    //   let { src } = this.props.match.params;
    //   let data = {
    //     ...this.state.formData,
    //     src: src,
    //     payment_method_id: "pm_card_visa",
    //   };
    //   this.props.postPayment(data);
    // }

    confirmPayment(payment_method_id, amount) {
        let { src } = this.props.match.params;
        let data = {
            product_amount: amount,
            src,
            payment_method_id,
        };
        this.props.postPayment(data);
    }

    renderFormData() {
        let onboarding = this.props.onboarding;
        if (onboarding) {
            if (onboarding.signup_form) {
                let { payment_schedule } = onboarding.signup_form;
                let formData = {
                    rushable_plan_id: onboarding.signup_form.rushable_plan_id,
                    payment_method_id: "",
                    signup_fee:
                        payment_schedule && payment_schedule.signup_fee
                            ? payment_schedule.signup_fee
                            : "",
                    singup_at:
                        payment_schedule && payment_schedule.singup_at
                            ? payment_schedule.singup_at
                            : "",
                    delay:
                        payment_schedule &&
                        (payment_schedule.delay || payment_schedule.delay === 0)
                            ? payment_schedule.delay
                            : "90",
                };
                this.setState({
                    formData,
                });
            }
            if (onboarding.rushable_plan) {
                this.setState({
                    rushablePlan: onboarding.rushable_plan,
                });
            }
        }
    }
    togglePolicyModal() {
        this.setState({
            policyModalOpen: !this.state.policyModalOpen,
        });
    }

    toggleTermsModal() {
        this.setState({
            termsModalOpen: !this.state.termsModalOpen,
        });
    }

    updatePaymentMethod(payment_method_id) {
        this.onFormChange("payment_method_id", payment_method_id);
    }

    goBack() {
        let { src, payment_step } = this.props.match.params;
        let activeLink = this.props.location.pathname;
        if (activeLink.includes("payment/3")) {
            this.props.history.push({
                pathname: `/onboard/${src}/questionnaire`,
            });
        } else if (activeLink.includes("payment/2")) {
            this.props.history.push({
                pathname: `/onboard/${src}/payment`,
            });
        } else if (activeLink.includes("payment")) {
            this.props.history.push({
                pathname: `/onboard/${src}/location`,
            });
        }
    }

    goToStep(step) {
        let { src } = this.props.match.params;
        this.setState({ step });
        this.props.history.push({
            pathname:
                step === 1
                    ? `/onboard/${src}/payment`
                    : `/onboard/${src}/payment/${step}`,
        });
    }

    render() {
        let signup_form = this.props.onboarding
            ? this.props.onboarding.signup_form ?? null
            : null;
        let brand_name = signup_form ? signup_form.brand_name ?? "" : "";
        return (
            <>
                <Switch>
                    <Route
                        path="/onboard/:src/payment/2"
                        render={(props) => (
                            <TermsPage
                                {...this.props}
                                data={this.state.formData}
                                onFormChange={this.onFormChange}
                                onPrev={this.goBack}
                                goToStep={this.goToStep}
                                togglePolicyModal={this.togglePolicyModal}
                                toggleTermsModal={this.toggleTermsModal}
                                changeStep={this.changeStep}
                                changeStepError={this.props.changeStepError}
                                changeStepRequest={this.props.changeStepRequest}
                            />
                        )}
                    />
                    <Route
                        path="/onboard/:src/payment/3"
                        render={(props) => (
                            <PaymentForm
                                {...this.props}
                                data={this.state.formData}
                                rushablePlan={this.state.rushablePlan}
                                onFormChange={this.onFormChange}
                                confirmPayment={this.confirmPayment}
                                updatePaymentMethod={this.updatePaymentMethod}
                                confirmPaymentError={
                                    this.props.confirmPaymentError
                                }
                                confirmPaymentRequest={
                                    this.props.confirmPaymentRequest
                                }
                                onPrev={this.goBack}
                                changeStep={this.changeStep}
                                changeStepError={this.props.changeStepError}
                                changeStepRequest={this.props.changeStepRequest}
                            />
                        )}
                    />
                    <Route
                        path="/onboard/:src/payment"
                        render={(props) => (
                            <ProcessorPage
                                {...this.props}
                                data={this.state.formData}
                                onFormChange={this.onFormChange}
                                onPrev={this.goBack}
                                goToStep={this.goToStep}
                                changeStep={this.changeStep}
                                clearChangeStepErr={
                                    this.props.clearChangeStepErr
                                }
                                changeStepError={this.props.changeStepError}
                                changeStepRequest={this.props.changeStepRequest}
                            />
                        )}
                    />
                </Switch>
                <PrivacyPolicy
                    toggle={this.togglePolicyModal}
                    open={this.state.policyModalOpen}
                    name={brand_name}
                />
                <TermsOfUse
                    toggle={this.toggleTermsModal}
                    open={this.state.termsModalOpen}
                    name={brand_name}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        onboarding: state.auth.onboarding,
        confirmPaymentError: state.confirm.confirmPaymentError,
        confirmPaymentRequest: state.confirm.confirmPaymentRequest,
        changeStepError: state.confirm.changeStepError,
        changeStepRequest: state.confirm.changeStepRequest,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        postPayment: (params) => dispatch(postPayment(params)),
        changeStep: (params) => dispatch(changeStep(params)),
        clearChangeStepErr: () => dispatch(clearChangeStepErr()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentIndex);
