import axios from "axios";
import { sendcode, landing } from "./auth";
import { toast } from "react-toastify";
import { MAIN_API_URL } from "../../utils/constant";

export const CONFIRM_REP_INFO_REQUEST = "CONFIRM_REP_INFO_REQUEST";
export const CONFIRM_REP_INFO_SUCCESS = "CONFIRM_REP_INFO_SUCCESS";
export const CONFIRM_REP_INFO_FAIL = "CONFIRM_REP_INFO_FAIL";

export const postRepInfo = (params) => (dispatch) => {
    dispatch({ type: CONFIRM_REP_INFO_REQUEST });
    let formData = {
        // ...params,
        rep_first_name: params.first_name,
        rep_last_name: params.last_name,
        rep_phone: params.phone,
        rep_email: params.email,
        password: params.password,
        password_confirmation: params.password_confirmed,
        rep_agrees_to_terms: params.rep_agrees_to_terms,
        src: params.src,
    };
    axios
        .request({
            url: "/confirm/representative",
            method: "post",
            data: formData,
        })
        .then(({ data }) => {
            if (data.redirect) return;
            dispatch({
                type: CONFIRM_REP_INFO_SUCCESS,
                msg: data.message,
            });
            dispatch(sendcode({ ...params, resend: false }));
        })
        .catch((err) => {
            if (err && err.message) {
                dispatch({
                    type: CONFIRM_REP_INFO_FAIL,
                    msg: err.message,
                    errors: err.errors,
                });
            }
        });
};

export const CONFIRM_LOCATION_REQUEST = "CONFIRM_LOCATION_REQUEST";
export const CONFIRM_LOCATION_SUCCESS = "CONFIRM_LOCATION_SUCCESS";
export const CONFIRM_LOCATION_FAIL = "CONFIRM_LOCATION_FAIL";
export const postLocation = (params) => (dispatch) => {
    dispatch({ type: CONFIRM_LOCATION_REQUEST });
    axios
        .request({
            url: "/confirm/location",
            method: "post",
            data: params,
        })
        .then(({ data }) => {
            if (data.redirect) return;

            dispatch({
                type: CONFIRM_LOCATION_SUCCESS,
                data: data.data,
                msg: data.message,
                errors: data.errors,
            });
            dispatch(landing({ src: params.src, refresh: true }));
        })
        .catch((err) => {
            if (err && err.message) {
                dispatch({
                    type: CONFIRM_LOCATION_FAIL,
                    msg: err.message,
                    errors: err.errors,
                });
            }
        });
};

export const CONFIRM_BUSINESS_REQUEST = "CONFIRM_BUSINESS_REQUEST";
export const CONFIRM_BUSINESS_SUCCESS = "CONFIRM_BUSINESS_SUCCESS";
export const CONFIRM_BUSINESS_FAIL = "CONFIRM_BUSINESS_FAIL";
export const postBusiness = (params) => (dispatch) => {
    dispatch({ type: CONFIRM_BUSINESS_REQUEST });
    axios
        .request({
            url: "/confirm/business",
            method: "post",
            data: params,
        })
        .then(({ data }) => {
            if (data.redirect) return;

            dispatch({
                type: CONFIRM_BUSINESS_SUCCESS,
                data: data.data,
                msg: data.message,
                errors: data.errors,
            });
            dispatch(landing({ src: params.src, refresh: true }));
        })
        .catch((err) => {
            if (err && err.message) {
                dispatch({
                    type: CONFIRM_BUSINESS_FAIL,
                    msg: err.message,
                    errors: err.errors,
                });
            }
        });
};

export const CONFIRM_OWNER_REQUEST = "CONFIRM_OWNER_REQUEST";
export const CONFIRM_OWNER_SUCCESS = "CONFIRM_OWNER_SUCCESS";
export const CONFIRM_OWNER_FAIL = "CONFIRM_OWNER_FAIL";
export const postOwner = (params) => (dispatch) => {
    dispatch({ type: CONFIRM_OWNER_REQUEST });
    axios
        .request({
            url: "/confirm/8",
            method: "post",
            data: params,
        })
        .then(({ data }) => {
            if (data.redirect) return;

            dispatch({
                type: CONFIRM_OWNER_SUCCESS,
                data: data.data,
                msg: data.message,
                errors: data.errors,
            });
            dispatch(landing({ src: params.src, refresh: true }));
        })
        .catch((err) => {
            if (err && err.message) {
                dispatch({
                    type: CONFIRM_OWNER_FAIL,
                    msg: err.message,
                    errors: err.errors,
                });
            }
        });
};

export const CONFIRM_PAYMENT_REQUEST = "CONFIRM_PAYMENT_REQUEST";
export const CONFIRM_PAYMENT_SUCCESS = "CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_FAIL = "CONFIRM_PAYMENT_FAIL";
export const postPayment = (params) => (dispatch) => {
    dispatch({ type: CONFIRM_PAYMENT_REQUEST });
    axios
        .request({
            url: "/confirm/payment",
            method: "post",
            data: params,
            timeout: 60 * 1000,
        })
        .then(({ data }) => {
            if (data.redirect) return;

            dispatch({
                type: CONFIRM_PAYMENT_SUCCESS,
                data: data.data,
                msg: data.message,
                errors: data.errors,
            });
            dispatch(landing({ src: params.src, refresh: true }));
        })
        .catch((err) => {
            if (err && err.message) {
                dispatch({
                    type: CONFIRM_PAYMENT_FAIL,
                    msg: err.message,
                    errors: err.errors,
                });
            }
        });
};

export const CONFIRM_PAYOUT_REQUEST = "CONFIRM_PAYOUT_REQUEST";
export const CONFIRM_PAYOUT_SUCCESS = "CONFIRM_PAYOUT_SUCCESS";
export const CONFIRM_PAYOUT_FAIL = "CONFIRM_PAYOUT_FAIL";
export const postPayout = (params) => (dispatch) => {
    dispatch({ type: CONFIRM_PAYOUT_REQUEST });
    return axios
        .request({
            url: "/confirm/payout",
            method: "post",
            data: params,
        })
        .then(({ data }) => {
            dispatch({
                type: CONFIRM_PAYOUT_SUCCESS,
                data: data.data,
                msg: data.message,
                errors: data.errors,
            });
            dispatch(landing({ src: params.src, refresh: true }));
            return true;
        })
        .catch((err) => {
            if (err && err.message) {
                dispatch({
                    type: CONFIRM_PAYOUT_FAIL,
                    msg: err.message,
                    errors: err.errors,
                });
            }
            return false;
        });
};

export const CHANGE_STEP_REQUEST = "CHANGE_STEP_REQUEST";
export const CHANGE_STEP_SUCCESS = "CHANGE_STEP_SUCCESS";
export const CHANGE_STEP_FAIL = "CHANGE_STEP_FAIL";
export const changeStep =
    (params, refresh = true) =>
    (dispatch) => {
        dispatch({ type: CHANGE_STEP_REQUEST });
        return axios
            .request({
                url: "/change_step",
                method: "post",
                data: params,
                cache: { ignoreCache: refresh },
            })
            .then(({ data }) => {
                dispatch({
                    type: CHANGE_STEP_SUCCESS,
                    data: data.data,
                    msg: data.message,
                    errors: data.errors,
                });
                dispatch(landing({ src: params.src, refresh: true }));
                return true;
            })
            .catch((err) => {
                if (err && err.message) {
                    dispatch({
                        type: CHANGE_STEP_FAIL,
                        msg: err.message,
                        errors: err.errors,
                    });
                }
                return false;
            });
    };

export const CLEAR_CHANGE_STEP_FAIL = "CLEAR_CHANGE_STEP_FAIL";
export const clearChangeStepErr = () => (dispatch) => {
    dispatch({ type: CLEAR_CHANGE_STEP_FAIL });
};

export const QUESTIONS_UPDATE_REQUEST = "QUESTIONS_UPDATE_REQUEST";
export const QUESTIONS_UPDATE_SUCCESS = "QUESTIONS_UPDATE_SUCCESS";
export const QUESTIONS_UPDATE_FAIL = "QUESTIONS_UPDATE_FAIL";
export const questionsUpdate =
    (params, refresh = true) =>
    (dispatch) => {
        dispatch({ type: QUESTIONS_UPDATE_REQUEST });
        return axios
            .request({
                url: "/update/questions",
                method: "post",
                data: params,
                cache: { ignoreCache: refresh },
            })
            .then(({ data }) => {
                dispatch({
                    type: QUESTIONS_UPDATE_SUCCESS,
                });
                return data;
            })
            .catch((err) => {
                if (err && err.message) {
                    dispatch({
                        type: QUESTIONS_UPDATE_FAIL,
                        msg: err.message,
                        errors: err.errors,
                    });
                    toast.error(err.message, {
                        autoClose: 10000,
                    });
                }
                return false;
            });
    };

export const CHANGE_LANGUAGE_REQUEST = "CHANGE_LANGUAGE_REQUEST";
export const CHANGE_LANGUAGE_SUCCESS = "CHANGE_LANGUAGE_SUCCESS";
export const CHANGE_LANGUAGE_FAIL = "CHANGE_LANGUAGE_FAIL";
export const changeLanguage =
    (params, refresh = true) =>
    (dispatch) => {
        dispatch({ type: CHANGE_LANGUAGE_REQUEST });
        return axios
            .request({
                url: "/change_language",
                method: "post",
                data: params,
                cache: { ignoreCache: refresh },
            })
            .then(({ data }) => {
                dispatch({
                    type: CHANGE_LANGUAGE_SUCCESS,
                });
                return data;
            })
            .catch((err) => {
                if (err && err.message) {
                    dispatch({
                        type: CHANGE_LANGUAGE_FAIL,
                        msg: err.message,
                        errors: err.errors,
                    });
                    toast.error(err.message, {
                        autoClose: 10000,
                    });
                }
                dispatch({ type: CHANGE_LANGUAGE_FAIL });
                return false;
            });
    };

export const QUESTIONS_CONFIRM_REQUEST = "QUESTIONS_CONFIRM_REQUEST";
export const QUESTIONS_CONFIRM_SUCCESS = "QUESTIONS_CONFIRM_SUCCESS";
export const QUESTIONS_CONFIRM_FAIL = "QUESTIONS_CONFIRM_FAIL";
export const questionsConfirm =
    (params, refresh = true) =>
    (dispatch) => {
        dispatch({ type: QUESTIONS_CONFIRM_REQUEST });
        return axios
            .request({
                url: "/confirm/questions",
                method: "post",
                data: params,
                cache: { ignoreCache: refresh },
            })
            .then(({ data }) => {
                dispatch({
                    type: QUESTIONS_CONFIRM_SUCCESS,
                });
                dispatch(landing({ src: params.src, refresh: true }));
                return data;
            })
            .catch((err) => {
                if (err && err.message) {
                    dispatch({
                        type: QUESTIONS_CONFIRM_FAIL,
                        msg: err.message,
                        errors: err.errors,
                    });
                    toast.error(err.message, {
                        autoClose: 10000,
                    });
                }
                return false;
            });
    };

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";
export const uploadFile =
    (params, refresh = true) =>
    (dispatch) => {
        dispatch({ type: UPLOAD_FILE_REQUEST });
        let formData = new FormData();
        formData.append("src", params.src);
        formData.append("type", params.type);
        formData.append("files[]", params.files[0]);
        return axios
            .request({
                url: "/menu/upload",
                method: "post",
                data: formData,
                cache: { ignoreCache: refresh },
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(({ data }) => {
                dispatch({
                    type: UPLOAD_FILE_SUCCESS,
                });
                return data;
            })
            .catch((err) => {
                if (err && err.message) {
                    dispatch({
                        type: UPLOAD_FILE_FAIL,
                        msg: err.message,
                        errors: err.errors,
                    });
                    toast.error(err.message, {
                        autoClose: 10000,
                    });
                }
                return false;
            });
    };
export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAIL = "UPLOAD_FILE_FAIL";
export const deleteFile =
    (params, refresh = true) =>
    (dispatch) => {
        dispatch({ type: UPLOAD_FILE_REQUEST });
        return axios
            .request({
                url: "/menu/delete",
                method: "post",
                data: params,
                cache: { ignoreCache: refresh },
            })
            .then(({ data }) => {
                dispatch({
                    type: UPLOAD_FILE_SUCCESS,
                });
                return data;
            })
            .catch((err) => {
                if (err && err.message) {
                    dispatch({
                        type: UPLOAD_FILE_FAIL,
                        msg: err.message,
                        errors: err.errors,
                    });
                    toast.error(err.message, {
                        autoClose: 10000,
                    });
                }
                return false;
            });
    };

export const ADDR_AUTO_FILL_REQUEST = "ADDR_AUTO_FILL_REQUEST";
export const ADDR_AUTO_FILL_SUCCESS = "ADDR_AUTO_FILL_SUCCESS";
export const ADDR_AUTO_FILL_FAIL = "ADDR_AUTO_FILL_FAIL";

export const autoFillAddress =
    ({ address_query }) =>
    (dispatch) => {
        dispatch({ type: "ADDR_AUTO_FILL_REQUEST" });
        axios
            .request({
                url: MAIN_API_URL + "/cw/user/address/auto_fill",
                params: { address_query },
                method: "get",
            })
            .then(({ data }) => {
                dispatch({
                    type: "ADDR_AUTO_FILL_SUCCESS",
                    data: data.data,
                });
            })
            .catch((err) => {
                if (err && err.message) {
                    dispatch({
                        type: "ADDR_AUTO_FILL_FAIL",
                        msg: err.message,
                    });
                }
            });
    };
