import React from "react";
import { useTranslation } from "react-i18next";
import currency from "currency.js";
import DragBar from "../DragBar";

const MAX = 349;

export default function EssentialPricing({
    product,
    discountSub,
    convenienceFee,
    subscription,
    setSubscription,
}) {
    const { t } = useTranslation();
    if (!product) {
        return null;
    }
    // const min = currency(product?.subscription_discount || 49);

    const min =
        currency(product?.subscription_discount).value > 49
            ? currency(product?.subscription_discount).value
            : 49;
    return (
        <div className="info-card gray mb-3 essential-pricing">
            <div className="er-title">{t("plan.monthly_subscription")}</div>
            <DragBar
                className="er-drag-bar"
                discount={currency(product?.subscription_discount).value}
                min={min}
                max={MAX}
                value={+subscription}
                onChange={(value) => {
                    setSubscription(value + "");
                }}
            />
            <div className="er-info">
                <div className="er-info-item">
                    <div className="er-info-title">
                        {t("plan.restaurant_pay")}
                    </div>
                    <div className="er-info-price">
                        {product.subscription_discount &&
                        Number(product.subscription_discount) > 0 ? (
                            <>
                                <span className="er-info-price-blue">
                                    {currency(subscription).format()}
                                </span>
                                <span> {currency(discountSub).format()}</span>
                            </>
                        ) : (
                            <span> {currency(subscription).format()}</span>
                        )}
                    </div>
                    <div className="er-info-type">{t("plan.per_month")}</div>
                </div>
                <div className="er-info-item er-info-item-right">
                    <div className="er-info-title">
                        {t("plan.customer_pay")}
                    </div>
                    <div className="er-info-price">
                        {currency(convenienceFee).format()}
                    </div>
                    <div className="er-info-type">{t("plan.per_order")}</div>
                </div>
            </div>
        </div>
    );
}
