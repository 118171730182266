import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

export default function QuestionnaireSubmit({
    questionsConfirmRequest,
    formData,
    isComplete,
    updating,
    address2Editing,
    submit,
    goBack,
}) {
    const { t } = useTranslation();
    const { has_existing_domain, self_domain } = formData;
    return (
        // <div className="qn-submit">
        <div className="qn-submit button-row btn-group-next d-flex">
            <Button
                block
                className="mt-0"
                size="lg"
                type="button"
                onClick={goBack}
            >
                {t("questionnaire.formData.backText")}
            </Button>
            <Button
                color="primary"
                block
                className="mt-0"
                size="lg"
                type="submit"
                disabled={
                    updating ||
                    address2Editing ||
                    (questionsConfirmRequest && !isComplete) ||
                    (!!has_existing_domain && !self_domain)
                }
                onClick={submit}
            >
                {questionsConfirmRequest ? (
                    <i className="fad fa-spinner fa-spin mr-2"></i>
                ) : null}
                {t("questionnaire.formData.buttonText")}
            </Button>
            {/* <Button className="qn-back-btn" color="secondary" onClick={goBack}>
                {t("questionnaire.formData.backText")}
            </Button>
            <Button
                type="submit"
                color="primary"
                className="qn-submit-btn"
                disabled={
                    updating ||
                    address2Editing ||
                    (questionsConfirmRequest && !isComplete) ||
                    (!!has_existing_domain && !self_domain)
                }
                onClick={submit}
            >
                {questionsConfirmRequest ? (
                    <i className="fad fa-spinner fa-spin mr-2"></i>
                ) : null}
                {t("questionnaire.formData.buttonText")}
            </Button> */}
        </div>
        // </div>
    );
}
