import React from "react";
import { connect } from "react-redux";
import VerificationForm from "../../components/Verification/VerificationForm";
import { landing } from "../../store/actions/auth";

class VerificationIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPoints: "",
    };
    this.tickLanding = this.tickLanding.bind(this);
    this.tickLoadingPoints = this.tickLoadingPoints.bind(this);
    this.setTimeoutVal = this.setTimeoutVal.bind(this);
  }

  componentDidMount() {
    this.intervalLanding = setInterval(() => this.tickLanding(), 10000);
    this.tickLoadingPoints();
    this.intervalPoints = setInterval(() => this.tickLoadingPoints(), 4000);
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    clearInterval(this.intervalLanding);
    clearInterval(this.intervalPoints);
  }

  tickLanding() {
    let { src } = this.props.match.params;
    this.props.landing({ src, refresh: true, loading: false });
  }

  tickLoadingPoints() {
    this.setTimeoutVal(0, "loadingPoints", "");
    this.setTimeoutVal(1000, "loadingPoints", ".");
    this.setTimeoutVal(2000, "loadingPoints", "..");
    this.setTimeoutVal(3000, "loadingPoints", "...");
  }

  setTimeoutVal(time, name, val) {
    setTimeout(() => {
      this.setState({ [name]: val });
    }, time);
  }

  render() {
    return <VerificationForm loadingPoints={this.state.loadingPoints} />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return { landing: (params) => dispatch(landing(params)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationIndex);
