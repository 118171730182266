import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./auth";
import confirm from "./confirm";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    confirm,
  });
