import React from "react";
import { connect } from "react-redux";

class PageTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = { profile_photo: null };
    }
    componentDidMount() {
        this.setState({
            profile_photo: this.props?.onboarding?.closer?.profile_photo,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.onboarding !== this.props.onboarding) {
            this.setState({
                profile_photo: this.props?.onboarding?.closer?.profile_photo,
            });
        }
    }

    render() {
        const { profile_photo } = this.state;
        return (
            <h1
                className="page-title"
                style={{
                    marginTop: profile_photo ? null : 24,
                    ...this.props.styles,
                }}
            >
                {this.props.children}
            </h1>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        onboarding: state.auth.onboarding,
    };
};

export default connect(mapStateToProps)(PageTitle);
